import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  contractsApi,
  documentsApi,
  offersApi,
  participantsApi,
  workspacesApi,
} from "../../api";
import {
  Activity,
  ActivityLog,
  ActivityLogResponse,
  DocumentSummary,
  GetDocuments200Response,
  Invitation,
  OfferDetail,
  Offers,
  Participant,
  Participants,
  Workflow,
  WorkspaceSummary,
} from "../../generated";

export interface GetWorkspaceResult {
  workspace?: WorkspaceSummary;
  documents?: Array<DocumentSummary>;
  participants?: Array<Participant>;
  offers?: Array<OfferDetail>;
  todos?: Array<Activity>;
  workflow?: Workflow;
  activityLog?: Array<ActivityLog>;
}

export const getWorkspace = createAsyncThunk<GetWorkspaceResult, number>(
  "workspace/getWorkspace",
  async (workspaceId) => {
    const calls = [];

    const response: GetWorkspaceResult = {};
    calls.push(workspacesApi.getWorkspace(workspaceId));
    calls.push(participantsApi.getParticipants(workspaceId));
    calls.push(offersApi.getOffers(workspaceId));
    calls.push(documentsApi.getDocuments(workspaceId));
    calls.push(workspacesApi.getWorkflow(workspaceId));
    calls.push(workspacesApi.getActivityLog(workspaceId));
    const [
      workspaceRes,
      participantsRes,
      offersRes,
      documentsRes,
      workflowRes,
      activityRes,
    ] = await Promise.all(calls);

    if (offersRes.status == 200) {
      response.offers = (offersRes.data as Offers).items!;
    }
    if (participantsRes.status == 200) {
      response.participants = (participantsRes.data as Participants).items!;
    }

    if (workflowRes.status == 200) {
      response.workflow = workflowRes.data as Workflow;
    }

    if (activityRes.status == 200) {
      response.activityLog = (activityRes.data as ActivityLogResponse).items;
    }
    response.workspace = workspaceRes.data as WorkspaceSummary;
    response.documents = (documentsRes.data as GetDocuments200Response).items;

    return response;
  }
);

export interface CompleteTaskArgs {
  workspaceId: number;
  taskName: string;
}
export const completeTask = createAsyncThunk<void, CompleteTaskArgs>(
  "workflowAction/completeTask",
  async (_) => {
    // await workspacesApi.performTask(workspaceId).catch((reason) => {
    //   console.log(reason);
    //   thunkApi.rejectWithValue("forbidden");
    // });
  }
);

export interface GetDocumentArgs {
  workspaceId: number;
  documentName: string;
}
export const getDocument = createAsyncThunk<string, GetDocumentArgs>(
  "workspace/getDocument",
  async ({ workspaceId, documentName }) => {
    // const oidcStorage = sessionStorage.getItem(
    //   `oidc.user:${authority}:${client_id}`
    // );
    // const user = User.fromStorageString(oidcStorage!);
    const response = await documentsApi.getDocument(workspaceId, documentName);

    // const response = await axios.get(
    //   `${konvei_api}/workspaces/${workspaceId}/agent-appointment/pdf`,
    //   {
    //     headers: { Authorization: `Bearer ${user.access_token}` },
    //     responseType: "blob",
    //   }
    // );

    const url = window.URL.createObjectURL(response.data);

    return url;
  }
);

export interface SendOffer {
  workspaceId: number;
  offerId: number;
}

export const refreshTasks = createAsyncThunk<Workflow, number>(
  "workspace/refreshTasks",
  async (workspaceId) => {
    const response = workspacesApi.getWorkflow(workspaceId);
    return (await response).data;
  }
);

export const sendToBuyerForSigning = createAsyncThunk<void, SendOffer>(
  "workspace/sendToBuyerForSigning",
  async ({ workspaceId, offerId }) => {
    offersApi.sendToBuyerForSigning(workspaceId, offerId);
  }
);

export const sendToSellerForSigning = createAsyncThunk<void, SendOffer>(
  "workspace/sendToSellerForSigning",
  async ({ workspaceId, offerId }) => {
    offersApi.sendToSellerForSigning(workspaceId, offerId);
  }
);

export const shareWithSeller = createAsyncThunk<void, SendOffer>(
  "workspace/shareWithSeller",
  async ({ workspaceId, offerId }) => {
    offersApi.presentOfferToSeller(workspaceId, offerId);
  }
);

export interface InviteSellerSolicitorArgs {
  workspaceId: number;
  emailAddress: string;
}
export const inviteSellerSolicitor = createAsyncThunk<
  void,
  InviteSellerSolicitorArgs
>("workspace/inviteSellerSolicitor", async ({ workspaceId, emailAddress }) => {
  const invitation: Invitation = {
    emailAddress: emailAddress,
  };
  await contractsApi.inviteSellerSolicitor(workspaceId, invitation);
});

export interface InviteBuyerSolicitorArgs {
  workspaceId: number;
  offerId: number;
  emailAddress: string;
}

export const inviteBuyerSolicitor = createAsyncThunk<
  void,
  InviteBuyerSolicitorArgs
>(
  "workspace/inviteBuyerSolicitor",
  async ({ workspaceId, offerId, emailAddress }) => {
    const invitation: Invitation = {
      emailAddress: emailAddress,
    };
    await workspacesApi.inviteBuyerSolicitor(workspaceId, offerId, invitation);
  }
);

export interface NewOfferResponse {
  offerId: number;
  workspaceId: number;
  buyerId: number;
  buyerSolicitorId: number;
}

export const newOffer = createAsyncThunk<NewOfferResponse, number>(
  "workspace/newOffer",
  async (workspaceId) => {
    const offerId = await offersApi.postOffer(workspaceId, {
      status: "current",
      id: 0,
      hasEncumbrances: false,
      hasNeighbourhoodDisputes: false,
      hasPool: false,
      hasPoolCertificate: false,
      hasSafetySwitches: false,
      hasSellerSolicitor: false,
      hasSmokeAlarms: false,
      hasTenant: false,
    });
    const buyerId = await offersApi.postOfferParticipant(
      workspaceId,
      offerId.data.id!,
      {
        role: "buyer",
      }
    );
    const buyerSolicitorId = await offersApi.postOfferParticipant(
      workspaceId,
      offerId.data.id!,
      { role: "buyerSolicitor" }
    );

    const response: NewOfferResponse = {
      offerId: offerId.data.id!,
      workspaceId: workspaceId,
      buyerId: buyerId.data.id!,
      buyerSolicitorId: buyerSolicitorId.data.id!,
    };
    return response;
  }
);
