import { User } from "oidc-client-ts";
import {
  AddressSearchApi,
  AgentAppointmentApi,
  Configuration,
  ContractsApi,
  DocumentsApi,
  TeamApi,
  OffersApi,
  ParticipantsApi,
  PropertyApi,
  WorkspacesApi,
} from "./generated";
import axios from "axios";
import { authority, client_id, konvei_api } from "./constants";

const config = new Configuration();

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  function (config) {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${authority}:${client_id}`
    );
    if (oidcStorage) {
      const user = User.fromStorageString(oidcStorage);
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);

const contractsApi = new ContractsApi(config, konvei_api, axiosInstance);
const offersApi = new OffersApi(config, konvei_api, axiosInstance);
const workspacesApi = new WorkspacesApi(config, konvei_api, axiosInstance);
const agentsApi = new AgentAppointmentApi(config, konvei_api, axiosInstance);
const participantsApi = new ParticipantsApi(config, konvei_api, axiosInstance);
const propertyApi = new PropertyApi(config, konvei_api, axiosInstance);
const documentsApi = new DocumentsApi(config, konvei_api, axiosInstance);
const teamsApi = new TeamApi(config, konvei_api, axiosInstance);


const addressSearchApi = new AddressSearchApi(
  config,
  konvei_api,
  axiosInstance
);

export {
  contractsApi,
  offersApi,
  workspacesApi,
  agentsApi,
  participantsApi,
  propertyApi,
  addressSearchApi,
  documentsApi,
  teamsApi,
};
