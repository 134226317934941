import { FC } from "react";

import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FormInputProps } from "./events";

const FormInputCheckbox: FC<FormInputProps> = ({ name, label, control }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={field.value ? field.value : false}
              onChange={field.onChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={label}
        />
      )}
    />
  );
};

export default FormInputCheckbox;
