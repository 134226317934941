import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { Participant1, TenantContact } from "../../generated";
import { getLicenceeDetails, getMyDetails, updateMyDetails } from "./thunks";
import { RootState } from "../../app/store";

export enum SettingsStatus {
  initial,
  loading,
  dirty,
  ready,
  updated,
}
export interface SettingsState {
  myDetails: Participant1;
  myDetailsStatus: SettingsStatus;
  licencee: TenantContact;
  licenceeStatus: SettingsStatus;
}
const initialState: SettingsState = {
  myDetails: {
    firstName: "",
    lastName: "",
    email: "",
    streetAddress1: "",
    locality: "",
    postCode: "",
    stateOrTerritory: "",
  },
  myDetailsStatus: SettingsStatus.initial,
  licencee: {
    contact: {
      firstName: "",
      lastName: "",
      email: "",
      streetAddress1: "",
      locality: "",
      postCode: "",
      stateOrTerritory: "",
      acn: "",
      abn: "",
    },
  },
  licenceeStatus: SettingsStatus.initial,
};
const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    getMyDetailsReducer(builder);
    licenceeReducers(builder);
  },
});

function getMyDetailsReducer(
  builder: ActionReducerMapBuilder<SettingsState>
): void {
  builder.addCase(getMyDetails.fulfilled, (state, action) => {
    state.myDetails = action.payload;
    state.myDetailsStatus = SettingsStatus.ready;
  });
  builder.addCase(updateMyDetails.fulfilled, (state, action) => {
    state.myDetails = action.payload;
    state.myDetailsStatus = SettingsStatus.updated;
  });
}


function licenceeReducers(
  builder: ActionReducerMapBuilder<SettingsState>
): void {
  builder.addCase(getLicenceeDetails.fulfilled, (state, action) => {
    state.licencee = action.payload;
    state.licenceeStatus = SettingsStatus.ready;
  });
}
export const {} = settingsSlice.actions;
export default settingsSlice.reducer;

export const settingsState = (state: RootState) => state.settings;
