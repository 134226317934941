import React, { useEffect, useState } from "react";
import BuyersDetails from "./components/buyers-details";
import Price from "./components/price";
import Settlement from "./components/settlement";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  OfferStepperStatus,
  offerStepperState,
  updateBuyer,
  updateOffer,
} from "./offer-stepper-slice";
import { Contract, Participant } from "../../generated";
import {
  addBuyer,
  deleteBuyer,
  getOffer,
  saveOffer,
  updateParticipant,
} from "./thunks";
import { useDebounce } from "usehooks-ts";

// const steps = [
//   "Buyer's Details",
//   // "Property",
//   "Offer",
//   // "Finance",
//   // "Building & Pest",
//   // "Special Conditions",
//   "Settlement Date",
//   "Review",
// ];

import { FC } from "react";
import Box from "@mui/material/Box";
import OfferDraftView from "../offer-draft/offer-draft-page";
import {
  validateBuyersDetails,
  validateOffer,
  validateSettlement,
} from "../../utils/validationUtils";
import { Grid } from "@mui/material";
import ErrorList from "../agent-appointment/components/error-list";

export interface OfferStepperViewProps {
  workspaceId: number;
  offerId: number;
}
const OfferStepperView: FC<OfferStepperViewProps> = ({
  workspaceId,
  offerId,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [validationErrors, setValidationErrors] = useState<
    { message: string; step: number }[]
  >([]);

  const state = useAppSelector(offerStepperState);
  const dispatch = useAppDispatch();

  const debouncedValue = useDebounce<Participant[] | undefined>(
    state.buyers,
    1000
  );

  const debounceBuyerSolicitor = useDebounce<Participant | undefined>(
    state.buyerSolicitor,
    1000
  );

  const debounceOffer = useDebounce<Participant | undefined>(state.offer, 1000);

  useEffect(() => {
    dispatch(getOffer({ workspaceId: workspaceId, offerId: Number(offerId) }));
  }, [offerId]);

  useEffect(() => {
    if (state.status != OfferStepperStatus.dirty) return;
    state.buyers.forEach((buyer: Participant) => {
      dispatch(
        updateParticipant({
          contractId: workspaceId,
          offerId: state.offer.id!,
          participant: buyer,
        })
      );
    });
  }, [debouncedValue]);

  useEffect(() => {
    if (state.status != OfferStepperStatus.dirty) return;
    dispatch(
      updateParticipant({
        contractId: workspaceId,
        offerId: state.offer.id!,
        participant: state.buyerSolicitor,
      })
    );
  }, [debounceBuyerSolicitor]);

  useEffect(() => {
    if (state.status != OfferStepperStatus.dirty) return;
    dispatch(saveOffer({ workspaceId: workspaceId, offer: state.offer }));
  }, [debounceOffer]);

  function onBuyersDetailsChanged(buyer: Participant): void {
    dispatch(updateBuyer(buyer));
  }

  // function onBuyersSolictorChanged(buyer: Participant): void {
  //   dispatch(updateBuyerSolicitor(buyer));
  // }

  function onAddBuyer(): void {
    dispatch(addBuyer({ workspaceId: workspaceId, offerId: state.offer.id! }));
  }

  function onOfferChanged(offer: Contract): void {
    dispatch(updateOffer(offer));
  }

  // function onContractChanged(contract: Contract): void {
  //   dispatch(updateContract(contract));
  // }

  function onRemoveBuyer(buyer: Participant): void {
    if (state.status != OfferStepperStatus.ready) return;

    dispatch(
      deleteBuyer({
        contractId: workspaceId,
        offerId: state.offer.id!,
        participantId: buyer.id!,
      })
    );
  }

  // function onStepChanged(step: number): void {
  //   setActiveStep(step);
  // }

  useEffect(() => {
    if (activeStep == 3) {
      const buyerErrors = validateBuyersDetails(state.buyers, 0);
      const offerErrors = validateOffer(state.offer, 1);
      const settlementErrors = validateSettlement(state.offer, 2);

      const toValidate = [...buyerErrors, ...offerErrors, ...settlementErrors];

      const allErrors = toValidate
        .filter((error) => error !== null)
        .map((error) => ({
          message: error,
          step: parseInt(error.match(/Part (\d+)/)?.[1] ?? "0"),
        }));
      setValidationErrors(allErrors);
    }
  }, [activeStep]);

  function handleErrorClick(step: number) {
    setActiveStep(step === 0 ? step : step - 1);
  }

  return (
    <Box>
      {state.status == OfferStepperStatus.initial ||
      state.status == OfferStepperStatus.loading ? (
        <></>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              position: "sticky",
              background: "#fff",
              paddingBottom: "1px",
              top: 0,
              zIndex: 1000,
            }}
          >
            {/* <KonveiStepper
              steps={steps}
              onStepChanged={onStepChanged}
              activeStep={activeStep}
            /> */}
          </Box>

          <div style={{ marginTop: 30, marginBottom: 30 }}>
            {activeStep == 0 ? (
              <BuyersDetails
                buyers={state.buyers}
                onBuyersDetailsChanged={onBuyersDetailsChanged}
                onAddBuyer={onAddBuyer}
                onBuyerRemoved={onRemoveBuyer}
              />
            ) : null}
            {/* {activeStep == 1 ? (
              <BuyersSolicitor
                participant={state.buyerSolicitor}
                onParticipantChanged={onBuyersSolictorChanged}
              />
            ) : null} */}
            {/* {activeStep == 2 ? (
              <PropertyForm
          offer={state.offer}
                contract={state.contract}
                workspace={state.workspace}
                onContractChanged={onContractChanged}
          onOfferChanged={onOfferChanged}
                workflow={state.workflow}
              />
            ) : null} */}
            {activeStep == 1 ? (
              <Price offer={state.offer} onOfferChanged={onOfferChanged} />
            ) : null}
            {/* {activeStep == 4 ? (
        <Finance offer={state.offer} onOfferChanged={onOfferChanged} />
            ) : null}
            {activeStep == 5 ? (
              <BuildingAndPest
          offer={state.offer}
          onOfferChanged={onOfferChanged}
              />
            ) : null}

            {activeStep == 6 ? (
              <SpecialConditions
          offer={state.offer}
          onOfferChanged={onOfferChanged}
              />
            ) : null} */}
            {activeStep == 2 ? (
              <Settlement offer={state.offer} onOfferChanged={onOfferChanged} />
            ) : null}
            {activeStep == 3 ? (
              <Grid
                container
                sx={{ marginTop: 2 }}
                justifyContent={"center"}
                spacing={2}
              >
                <Grid item sx={{ width: "700px" }}>
                  {validationErrors.length > 0 ? (
                    <ErrorList
                      errors={validationErrors}
                      handleErrorClick={handleErrorClick}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <OfferDraftView
                    offerId={offerId}
                    workspaceId={workspaceId}
                    workspaceRole={state.workspace.workspace?.role!}
                    validationErrors={validationErrors}
                  />
                </Grid>
              </Grid>
            ) : null}
          </div>
        </React.Fragment>
      )}
    </Box>
  );
};

export default OfferStepperView;
