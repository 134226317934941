import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Box } from "@mui/material";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { saveContract } from "../thunks";

const NeighbourhoodDisputes: React.FC = ({}) => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();

  function onChanged(event: ChangeEvent<HTMLInputElement>): void {
    const newContract = { ...state.contract };
    switch (event.currentTarget.name) {
      case "hasNeighbourhoodDisputes":
        newContract.hasNeighbourhoodDisputes =
          event.currentTarget.value == "true";
        break;

      default:
        break;
    }
    dispatch(saveContract(newContract));
  }

  return (
    <FormWrapper title="Part 9 - Neighbourhood Disputes">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} lg={12}>
            <Typography variant="body1">
              Does the Seller give notice to the Buyer in accordance with
              Section 83 of the Neighbourhood Disputes (Dividing Fences and
              Trees) Act 2011 that the land is affected by an application to, or
              an order made by, QCAT in relation to a tree on the Land, a copy
              of which has been given to the Buyer prior to the Buyer signing
              the contract.
            </Typography>
          </Grid>
          <Grid item sm={12} lg={12}>
            <RadioGroup
              value={state.contract.hasNeighbourhoodDisputes}
              name="hasNeighbourhoodDisputes"
              onChange={onChanged}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        </Grid>
      </Box>
    </FormWrapper>
  );
};

export default NeighbourhoodDisputes;
