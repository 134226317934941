import { useGridApiContext } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid/models";
import { FC } from "react";
import {
  NumericFormat,
} from "react-number-format";
import {
  currencyFromBaseUnit,
  currencyToBaseUnit,
} from "../../../helpers/helpers";
import TextField from "@mui/material/TextField";

const CurrencyDataGridEditComponent: FC<GridRenderCellParams<any, number>> = ({
  id,
  value,
  field,
}) => {
  const apiRef = useGridApiContext();

  return (
    <NumericFormat
      customInput={TextField}
      prefix="$ "
      variant="standard"
      thousandSeparator
      size="small"
      fixedDecimalScale
      decimalScale={2}
      value={currencyFromBaseUnit(value)}
      onValueChange={(ds) => {
        apiRef.current.setEditCellValue({
          id,
          field,
          value: currencyToBaseUnit(ds.floatValue),
        });
      }}
    ></NumericFormat>
  );
};

export default CurrencyDataGridEditComponent;
