import { createAsyncThunk } from "@reduxjs/toolkit";
import { offersApi, workspacesApi } from "../../api";
import {
  OfferDetail,
  Workflow,
  WorkspaceSummaryNew,
} from "../../generated";

export const getWorkspaces = createAsyncThunk<Array<WorkspaceSummaryNew>, void>(
  "workspace/getWorkspaces",
  async () => {
    const result = await workspacesApi.getWorkspaces2();
    const foo = result.data.items!;
    return foo;
  }
);

export interface GetWorkflowStateArgs {
  workspaceId: number;
}

export interface GetWorkflowStateResponse {
  workspaceId: number;
  workflow: Workflow;
}
export const getWorkflowState = createAsyncThunk<
  GetWorkflowStateResponse,
  GetWorkflowStateArgs
>("workspace/getWorkflowState", async ({ workspaceId }) => {
  const result = await workspacesApi.getWorkflow(workspaceId);
  return { workspaceId: workspaceId, workflow: result.data };
});

export interface GetOfferArgs {
  workspaceId: number;
}
export const getOffers = createAsyncThunk<OfferDetail[], GetOfferArgs>(
  "workspace/getOffers",
  async ({ workspaceId }) => {
    const result = await offersApi.getOffers(workspaceId);

    return result.data.items ?? [];
  }
);
