import { FC } from "react";
import { WorkspaceState } from "../workspace-slice";
import { OnOfferClicked } from "../../../components/events";
import DocumentsTable, {
  OnDocumentClicked,
} from "../../../components/documents-table";
import { OnInviteSolicitor } from "../events";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import LCCard from "../../../components/cards/lc-card";
import { cleanAndCapitalize } from "../../../helpers/helpers";
import SelectSolicitor, { ActingForEnum } from "./actions/select-solicitor";
import OfferCard from "../../../components/offer-card";
import ActivityLog from "./activity-log";
import BottomNavToggle from "./bottom-nav";
import { Participant } from "../../../generated";
import ParticipantList from "./participants-list";
import { useAuth } from "react-oidc-context";
import { useParams } from "react-router-dom";
import OfferAction from "./actions/offer";

export interface SellerWorkspaceViewProps {
  state: WorkspaceState;
  onOfferClicked: OnOfferClicked;
  onDocumentView: OnDocumentClicked;
  onInviteSellerSolicitor: OnInviteSolicitor;
}

const SellerWorkspaceView: FC<SellerWorkspaceViewProps> = ({
  state,
  onDocumentView,
  onInviteSellerSolicitor,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAuth();
  const params = useParams();

  const filterParticipantsByRole = (participant: Participant) => {
    return (
      participant.role === "sellerAgent" ||
      participant.role === "sellerSolicitor"
    );
  };

  const getFirstNonCompletedStep = () => {
    if (state.workflow?.contractPreparation !== "complete") {
      return {
        step: "contractPreparation",
        status: "",
        value: state.workflow?.contractPreparation,
      };
    }
    if (state.workflow?.offers.length !== 0) {
      return { step: "offers", value: "offers" };
    }

    return { step: "completed", value: "All tasks are completed" };
  };

  const renderStatus = () => {
    const { step, value } = getFirstNonCompletedStep();
    let status = "";
    let imageSrc = "";

    switch (step) {
      case "contractPreparation":
        status = "Contract Preparation";
        imageSrc = `/${value}.png`;
        break;
      case "offers":
        status = "Accepting Offers";
        imageSrc = `/${value}.png`;
        break;
      default:
        status = "Accepting Offers";
        imageSrc = "";
        break;
    }

    return (
      <>
        <Typography variant="h6" component="p">
          {status ? cleanAndCapitalize(status) : null}
        </Typography>
        <Typography variant="body1" component="p">
          {value ? cleanAndCapitalize(value) : null}
        </Typography>
        {imageSrc && (
          <img
            src={imageSrc}
            alt={status}
            style={{ maxWidth: 350, width: "100%", margin: "0 auto" }}
          />
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column-reverse" },
        gap: 2,
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          flex: "7",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <LCCard
          title={`Workspace`}
          titleColor="#000"
          bgColor="#fff"
          txtColor="#000"
        >
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Box
              sx={{
                borderRadius: "30px",
                marginBottom: 2,
                width: { lg: "700px", md: "100%" },
              }}
            >
              <img
                src={`https://landconnex-prod-public.ap-south-1.linodeobjects.com/${state.workspace.workspace?.id}.jpeg`}
                alt="property-image"
                style={{ borderRadius: "12px", width: "100%" }}
              />
              <Box>
                <Typography
                  variant="h5"
                  component="span"
                  className="roboto-bold"
                >
                  {`${state.workspace.property?.streetAddress1},
      ${state.workspace.property?.locality} ${state.workspace.property?.stateOrTerritory},
      ${state.workspace.property?.postCode}`}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ color: "#3e3e3e" }}
                  >
                    Lot:{" "}
                    <Typography variant="body1" component="span">
                      {state.workspace.property?.lot}
                    </Typography>
                  </Typography>

                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      color: "#3e3e3e",
                      ml: 1,
                      "::before": {
                        content: "'•'",
                        marginRight: "8px",
                      },
                    }}
                  >
                    Plan:{" "}
                    <Typography variant="body1" component="span">
                      {state.workspace.property?.plan}
                    </Typography>
                  </Typography>

                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      color: "#3e3e3e",
                      ml: 1,
                      "::before": {
                        content: "'•'",
                        marginRight: "8px",
                      },
                    }}
                  >
                    Title Reference:{" "}
                    <Typography variant="body1" component="span">
                      {state.workspace.property?.titleReference}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              borderTop: "1px solid silver",
              marginTop: 3,
              marginBottom: 2,
            }}
          />
          <ParticipantList
            participants={state.participants}
            currentUserEmail={auth.user?.profile.email!}
            roles={["sellerAgent", "sellerSolicitor"]}
          />
        </LCCard>
        {state.offers.length > 0 &&
          (params["offerId"] == null ? (
            <LCCard
              title="Offers"
              titleColor="#000"
              bgColor="#fff"
              txtColor="#6d6d6d"
            >
              <Grid spacing={3} container>
                {state.offers.map((offer, index) => (
                  <Grid key={index} item sm={6}>
                    <OfferCard
                      offer={offer}
                      workspace={state.workspace.workspace!}
                      workflow={state.workflow!}
                    />
                  </Grid>
                ))}
              </Grid>
            </LCCard>
          ) : (
            <OfferAction
              workspaceSummary={state.workspace}
              workflow={state.workflow!}
              offer={
                state.offers.find(
                  (o) => o.offer.id == Number.parseInt(params["offerId"]!)
                )!.offer
              }
            />
          ))}
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          position: { md: "sticky", xs: "relative" },
          top: { md: 89, xs: 0 },
          flex: "3",
          width: { md: "auto", xs: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <LCCard bgColor="#fff" titleColor="#000">
            <Box
              sx={{
                textAlign: "center",
                p: {
                  fontSize: "clamp(2.8rem, 1.5vw, 3rem)",
                  fontWeight: "bold",
                  color: "#8F1219",
                },
              }}
            >
              {renderStatus()}
            </Box>
          </LCCard>

          <LCCard title="Activity Log" bgColor="#fff" titleColor="#000">
            <ActivityLog activityLog={state.activityLog} />
          </LCCard>
          {state.workflow?.contractPreparation == "selecting-solicitor" && (
            <SelectSolicitor
              actingFor={ActingForEnum.Seller}
              onInviteBuyerSolicitor={() => {}}
              onInviteSellerSolicitor={onInviteSellerSolicitor}
            />
          )}

          <LCCard
            title={"Documents"}
            txtColor="#273E47"
            bgColor="#014597"
            circleElementBefore="#4a84ad"
            circleElementAfter="#023676"
          >
            <DocumentsTable
              onDocumentViewClicked={onDocumentView}
              documents={state.documents}
            />
          </LCCard>
        </Box>
      </Box>
      {isMobile && (
        <Box sx={{ position: "relative" }}>
          <BottomNavToggle
            participants={state.participants}
            filterFunction={filterParticipantsByRole}
          />
        </Box>
      )}
    </Box>
  );
};

export default SellerWorkspaceView;
