import React from "react";
import { Typography } from "@mui/material";
import LCCard from "../../../components/cards/lc-card";

interface Error {
  message: string;
  step: number;
}

interface ErrorListProps {
  errors: Error[];
  handleErrorClick: (step: number) => void;
}

const ErrorList: React.FC<ErrorListProps> = ({ errors, handleErrorClick }) => (
  <LCCard
    title="Please resolve missing fields to proceed"
    txtColor="#000"
    titleColor="#000"
    bgColor="#fff"
  >
    <Typography variant="body2">
      {errors.map((error, index) => (
        <ul key={index} style={{ padding: 0, listStyleType: "disc" }}>
          {error.message.split(", ").map((message, msgIndex) => (
            <li
              key={msgIndex}
              onClick={() => handleErrorClick(error.step)}
              style={{
                margin: msgIndex === 0 ? "0 0 15px 0" : "0 0 0 15px",
                padding: msgIndex === 0 ? "10px 5px" : "5px 0",
                cursor: "pointer",
                color: msgIndex === 0 ? "#fff" : "#c20029",
                fontSize: msgIndex === 0 ? "16px" : "inherit",
                fontWeight: msgIndex === 0 ? "600" : "inherit",
                display: "block",
                background: msgIndex === 0 ? "#273E47" : "",
                listStyleType: "disc",
              }}
            >
              {message.split(" ").map((word, wordIndex) => {
                if (word.startsWith("Part")) {
                  return (
                    <span
                      key={wordIndex}
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: 300,
                      }}
                    >
                      {`(${word} `}
                    </span>
                  );
                } else if (
                  wordIndex > 0 &&
                  message.split(" ")[wordIndex - 1] === "Part"
                ) {
                  return (
                    <span
                      key={wordIndex}
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: 300,
                      }}
                    >
                      {`${word})`}
                    </span>
                  );
                } else {
                  return <span key={wordIndex}>{word} </span>;
                }
              })}
            </li>
          ))}
        </ul>
      ))}
    </Typography>
  </LCCard>
);

export default ErrorList;
