import React, { ReactNode } from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

interface FormWrapperProps {
  title: string;
  notes?: { title: string; items: string[] }[];
  children: ReactNode;
}

const FormWrapper: React.FC<FormWrapperProps> = ({
  title,
  notes,
  children,
}) => {
  return (
    <Box
      sx={{
        maxWidth: "1024px",
        margin: "15px auto",
      }}
    >
      <Box
        sx={{
          background: "#8F1219",
          color: "#fff",
          padding: "10px 15px",
          marginBottom: "30px",
        }}
      >
        <Typography variant="h5">{title}</Typography>
      </Box>
      {notes &&
        notes.map((note, index) => (
          <Box
            key={index}
            sx={{
              background: "#f0eeeb",
              borderRadius: "15px",
              padding: "15px",
              textAlign: "justify",
              marginBottom: "15px",
            }}
          >
            <Typography variant="h6" sx={{ color: "#c20029" }}>
              {note.title}
            </Typography>
            <List
              sx={{
                padding: 0,
                paddingLeft: "30px",
                listStyleType: "disc",
                "& li::marker": {
                  fontSize: "0.8em",
                },
              }}
            >
              {note.items.map((item, idx) => (
                <ListItem key={idx} sx={{ padding: 0, display: "list-item" }}>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      {children}
    </Box>
  );
};

export default FormWrapper;
