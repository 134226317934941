import { Fragment } from "react";
import { Box, Avatar, Typography, styled } from "@mui/material";
import { Participant } from "../../../generated";

interface ParticipantListProps {
  participants: Participant[];
  currentUserEmail: string;
  roles: Array<string>;
}

const ParticipantList = ({
  participants,
  currentUserEmail,
  roles,
}: ParticipantListProps) => {
  const roleTitles: Record<string, string> = {
    sellerAgent: "Agent",
    seller: "Seller",
    sellerSolicitor: "Seller Solicitor",
    buyer: "Buyer",
    buyerSolicitor: "Buyer Solicitor",
  };
  return (
    <Box>
      {participants
        .filter((participant) => {
          return (
            roles.includes(participant.role!) &&
            participant.firstName &&
            participant.phone
          );
        })
        .map((participant, index) => {
          const title = roleTitles[participant.role!] || "";

          if (participant.email === currentUserEmail) {
            return null;
          }

          return (
            <Fragment key={index}>
              <Box
                sx={{
                  display: "flex",
                  padding: "15px 0",
                  gap: 2,
                }}
              >
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    bgcolor: "#8F1219",
                  }}
                >
                  {participant.firstName
                    ? `${participant.firstName.charAt(
                        0
                      )}${participant.lastName?.charAt(0)}`
                    : ""}
                </Avatar>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                  }}
                >
                  <Typography className="roboto-bold">
                    {title && participant.firstName
                      ? `${title} - `
                      : `${title}`}
                    {participant.firstName} {participant.lastName}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <StyledLink
                      href={`tel:${participant.phone}`}
                      sx={{
                        color: "#000",
                        textDecoration: "none",
                      }}
                    >
                      {participant.phone}
                    </StyledLink>
                    <Box
                      sx={{
                        width: "3px",
                        height: "3px",
                        borderRadius: "50%",
                        backgroundColor: "#868686",
                        margin: "auto 8px",
                      }}
                    />
                    <StyledLink
                      href={`mailto:${participant.email}`}
                      sx={{
                        color: "#000",
                        textDecoration: "none",
                      }}
                    >
                      {participant.email}
                    </StyledLink>
                  </Box>
                </Box>
              </Box>
            </Fragment>
          );
        })}
    </Box>
  );
};

const StyledLink = styled("a")(({ theme }) => ({
  ...theme.typography.body2,
  color: "silver",
}));

export default ParticipantList;
