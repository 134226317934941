import { createAsyncThunk } from "@reduxjs/toolkit";
import { contractsApi } from "../../api";



export const initialise = createAsyncThunk("contracts/initialise", async () => {
  const response = await contractsApi.getContract(1);
  return response.data;
});

// export const create = createAsyncThunk("contracts/create", async () => {
//   const arg: NewMatter = {matterReference: "sdfsdf" +  Math.round(Date.now() / 1000)};
//   const response = await mattersApi.postMatters(arg);
//   return response.data;
// });
