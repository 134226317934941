import { Box } from "@mui/material";
import React from "react";
import styles from "./workspace-timeline.module.css";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Workflow } from "../generated";

export type OnStepChanged = (step: number) => void;
export interface WorkspaceTimelineProps {
  workflow: Workflow;
}

const steps = [
  "Agent Appointment",
  "Contract disclosures",
  "Contract Signing",
  "Settlement",
];

const WorkspaceTimeline: React.FC<WorkspaceTimelineProps> = ({ workflow }) => {
  const renderStep = (step: string, index: number) => {
    const isCompleted = index < steps.indexOf(workflow.state);
    const isActive =
      workflow.state === step && workflow.subState !== "complete";
    const subStateLabel = isCompleted
      ? "completed"
      : workflow.state === step
      ? workflow.subState
      : "not started";

    return (
      <div
        key={step}
        className={`${styles["step"]} ${
          isCompleted ? styles["completed"] : ""
        } ${isActive ? styles["active"] : ""}`}
      >
        {isCompleted && (
          <CheckOutlinedIcon fontSize="large" sx={{ color: "#21ba45" }} />
        )}
        <div className={styles["content"]}>
          <div className={`${styles["title"]} roboto-medium`}>{step}</div>
          <div className={`${styles["description"]} roboto-regular`}>
            {subStateLabel}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Box
      sx={{
        marginTop: 5,
        marginBottom: 5,
        display: "block",
        width: "auto",
        marginX: "auto",
        textAlign: "center",
      }}
    >
      <Box className={`${styles["timeline"]}`}>
        {steps.map((step, index) => renderStep(step, index))}
      </Box>
    </Box>
  );
};

export default WorkspaceTimeline;
