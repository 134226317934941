import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { accept } from "./thunks";

export enum AcceptInvitationStatus {
  initial,
  acceptInvitationPending,
  acceptInvitationFulfilled,
  failure,
}

export interface AcceptInvitationState {
  status: AcceptInvitationStatus;
  invitation: string;
}

const initialState: AcceptInvitationState = {
  status: AcceptInvitationStatus.initial,
  invitation: "",
};

export const acceptInvitationSlice = createSlice({
  name: "acceptInvitation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    acceptReducers(builder);
  },
});

function acceptReducers(
  builder: ActionReducerMapBuilder<AcceptInvitationState>
) {
  builder.addCase(accept.pending, (state) => {
    state.status = AcceptInvitationStatus.acceptInvitationPending;
  });
  builder.addCase(accept.rejected, (state) => {
    state.status = AcceptInvitationStatus.failure;
  });
  builder.addCase(accept.fulfilled, (state, action) => {
    state.status = AcceptInvitationStatus.acceptInvitationFulfilled;
    state.invitation = action.payload;
  });
}

export const {} = acceptInvitationSlice.actions;
export default acceptInvitationSlice.reducer;
export const acceptInvitationState = (state: RootState) =>
  state.acceptInvitation;
