import { FC, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  useTheme,
  Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../generated";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { participantSchema } from "../../agent-appointment/components/schemas";
import ParticipantNameForm from "../../../components/participant-name-form";
import ContactDetails from "../../../components/contact-details";
import AddressForm from "../../../components/address-form";
import { useAppDispatch } from "../../../app/hooks";
import { updateUser } from "../thunks";
import AgentLicenceeInformation from "../../../components/agent-licencee-information";

interface MemberViewProps {
  sub: string;
  member: Partial<Participant>;
}

const MemberView: FC<MemberViewProps> = ({ member, sub }) => {
  const [editMode, setEditMode] = useState(false);
  //const [editedMember, setEditedMember] = useState(member);
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const schema = participantSchema;

  const { control, reset, trigger, handleSubmit, setValue } =
    useForm<Participant>({
      defaultValues: member,
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    reset(member);
    trigger();
  }, [member]);

  const onSubmit: SubmitHandler<Participant> = (data) => {
    console.log(data);
    dispatch(
      updateUser({
        sub: sub,
        teamParticipant: { participant: data, sub: sub },
      })
    ).then(() => {
      setEditMode(false);
    });
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Member Details
      </Typography>
      {editMode ? (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel size="small" id="clientTypeLabel">
                  Client Type
                </InputLabel>
                <Select
                  size="small"
                  labelId="clientTypeLabel"
                  name="participantType"
                  label="Client Type"
                  defaultValue={member.participantType}
                  onChange={(event) => {
                    const updatedClient = {
                      ...member,
                      participantType: event.target
                        .value as ParticipantParticipantTypeEnum,
                    };
                    dispatch(
                      updateUser({
                        sub: sub,
                        teamParticipant: {
                          sub: sub,
                          participant: updatedClient,
                        },
                      })
                    );
                  }}
                >
                  {Object.values(ParticipantParticipantTypeEnum).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <ParticipantNameForm
                control={control}
                participantType="individual"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Contact
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <ContactDetails control={control} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Address
              </Typography>
            </Grid>

            <AddressForm
              address={member}
              setValue={setValue}
              control={control}
            />
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" color={theme.palette.text.secondary}>
                Licencee Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ParticipantNameForm
                control={control}
                organisationLabel="Licencee Name"
                participantType="organisation"
              />
            </Grid>
            <AgentLicenceeInformation control={control} />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar sx={{ width: 100, height: 100, bgcolor: "#8F1219" }}>
              {`${member.firstName?.charAt(0)}${member.lastName?.charAt(0)}`}
            </Avatar>
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>First Name:</strong> {member.firstName}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Middle Name:</strong> {member.middleNames}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Last Name:</strong> {member.lastName}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Email:</strong> {member.email}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Mobile Phone:</strong> {member.mobilePhone}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Organisation Name:</strong> {member.organisationName}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Street Address 1:</strong> {member.streetAddress1}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Street Address 2:</strong> {member.streetAddress2}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Locality:</strong> {member.locality}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>State or Territory:</strong> {member.stateOrTerritory}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Post Code:</strong> {member.postCode}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Country:</strong> {member.country}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Phone:</strong> {member.phone}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Country Code:</strong> {member.countryCode}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>ABN:</strong> {member.abn}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>ACN:</strong> {member.acn}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Registered for GST:</strong>{" "}
            {member.registeredForGst ? "Yes" : "No"}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setEditMode(true)}
          >
            Edit
          </Button>
          {/* <ClientDetail
            key={member.id}
            client={member}
            index={1}
            onChanged={() => {}}
            onDelete={() => {}}
          /> */}
        </Box>
      )}
    </Box>
  );
};

export default MemberView;
