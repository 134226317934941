import { Grid } from "@mui/material";
import React from "react";
import { Control } from "react-hook-form";
import FormInputPattern from "./form-input-pattern";
import FormInputDatePicker from "./form-input-date-picker";

export interface AgentLicenceeInformationProps {
    control: Control
}
const AgentLicenceeInformation = (props: AgentLicenceeInformationProps) => {

    return (
        <React.Fragment>
          <Grid item  xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormInputPattern
                  name="licenceeNumber"
                  format="##########"
                  label="Licencee Number"
                  control={props.control}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInputDatePicker
                  name="licenceeExpiryDate"
                  label="Expiry Date"
                  control={props.control}
                  key="licenceeExpiryDate"
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
}

export default AgentLicenceeInformation;