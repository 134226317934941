import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRowModesModel,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { Fee } from "../../../generated";
import { OnFeeChanged } from "../../../components/events";
import { Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { australianCurrencyFormatter } from "../../../helpers/helpers";
import CurrencyDataGridEditComponent from "./currency-data-grid-edit-component";
import Typography from "@mui/material/Typography";
import LCButton from "../../../components/button";

export interface FeesDataGridProps {
  fees: Array<Fee>;
  onFeeChanged: OnFeeChanged;
  onFeeDeleted: OnFeeChanged;
  onFeeAdded: () => void;
}

const FeesDataGrid: React.FC<FeesDataGridProps> = ({
  fees,
  onFeeChanged,
  onFeeDeleted,
  onFeeAdded,
}) => {
  const [gridWidth, setGridWidth] = useState(0);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const handleResize = (element: HTMLElement) => {
    setGridWidth(element.clientWidth);
  };
  useEffect(() => {
    const columns: GridColDef[] = [
      {
        field: "description",
        headerName: "Description",
        width: gridWidth * 0.3,
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const hasError = params.props.value.length == 0;
          return { ...params.props, error: hasError };
        },
      },
      {
        field: "estimatedAmount",
        headerName: "Estimated Amount",
        renderCell: (p) => {
          return (
            <Typography>
              {p.value
                ? australianCurrencyFormatter.format(p.value / 100)
                : null}
            </Typography>
          );
        },
        renderEditCell: (p) => {
          return <CurrencyDataGridEditComponent {...p} />;
        },
        width: gridWidth * 0.3,
        editable: true,
      },
      {
        field: "whenPayable",
        headerName: "When Payable",
        width: gridWidth * 0.2,
        editable: true,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: gridWidth * 0.05,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<Delete />}
              label="Cancel"
              className="textPrimary"
              color="inherit"
              onClick={() => onFeeDeleted(params.row as Fee)}
            />,
          ];
        },
      },
    ];
    setColumns(columns);
  }, [gridWidth]);
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={fees}
        rowSelection={false}
        editMode="row"
        hideFooter={true}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { onFeeAdded: onFeeAdded },
        }}
        columns={columns}
        processRowUpdate={(newRow) => {
          onFeeChanged(newRow);
          return newRow;
        }}
        onProcessRowUpdateError={(e) => {
          console.log(e);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        onResize={() =>
          handleResize(
            document.querySelector(".MuiDataGrid-root") as HTMLElement
          )
        }
      />
    </Box>
  );
};

interface EditToolbarProps {
  onFeeAdded: () => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  return (
    <GridToolbarContainer sx={{ maxWidth: "150px" }}>
      <LCButton
        label=" Add Fee"
        color="#8F1219"
        onClick={() => props.onFeeAdded()}
      />
    </GridToolbarContainer>
  );
}

export default FeesDataGrid;
