import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FC, Fragment, useCallback, useEffect } from "react";
import { AgentProps } from "../../../components/events";
import FeesDataGrid from "./fees-data-grid";
import BenefitToAgentDataGrid from "./benefit-to-agent-data-grid";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, debounce } from "@mui/material";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  AgentAppointment,
  AgentAppointmentCommissionPaymentTermEnum,
  Participant,
} from "../../../generated";
import * as yup from "yup";
import FormInputDatePicker from "../../../components/form-input-date-picker";
import FormWrapper from "./form-wrapper";
import CurrencyField from "../../../components/currency-field";

const Authorisation: FC<AgentProps> = ({
  agentAppointment: agent,
  fees,
  benefits,
  onAgentAppointmentChanged,
  onFeeAdded,
  onFeeChanged,
  onFeeDeleted,
  onBenefitToAgentAdded,
  onBenefitToAgentChanged,
  onBenefitToAgentDeleted,
}) => {
  const schema = yup.object<AgentAppointment>({
    commissionPaymentTermInstructions: yup
      .string()
      .when(["commissionPaymentTerm"], {
        is: (val: string) =>
          val == AgentAppointmentCommissionPaymentTermEnum.Other,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
    marketingCostsDueDate: yup.date().required(),
    marketingAuthorisedAmount: yup.number().required(),
    repairsAndMaintenanceAuthorisedAmount: yup.number(),
    marketingInstructions: yup.string(),
  });

  const { handleSubmit, control, formState, reset } = useForm<AgentAppointment>(
    {
      defaultValues: agent,
      resolver: yupResolver(schema),
    }
  );

  useEffect(() => {
    reset(agent);
  }, [agent]);

  const onSubmit: SubmitHandler<Participant> = (data) => {
    onAgentAppointmentChanged(data);
  };

  const watchedData = useWatch({
    control: control,
    defaultValue: agent,
  });
  const debouncedSave = useCallback(
    debounce(() => {
      console.log("Saving");
      handleSubmit(onSubmit)();
    }, 1000),
    []
  );

  useDeepCompareEffect(() => {
    console.log("Triggered");
    if (formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);
  return (
    <FormWrapper title="Part 7 - Authorisation to incurr fees, charges and expenses">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          className="section-1"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 4,
            borderBottom: "1px solid silver",
            paddingBottom: 5,
          }}
        >
          <Typography variant="h6" component="span" className="roboto-bold">
            Section 1 - Adveritising / Marketing
          </Typography>
          <Box>
            <Typography variant="body1">
              <strong>To the client</strong>
            </Typography>
            <Typography variant="body2">
              Your agent may either complete this section or attach annexures of
              marketing/ advertising activities. In either case, the authorised
              amount must be written here.
            </Typography>
          </Box>
          <Grid>
            <Fragment>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="marketingInstructions"
                    label="Description"
                    multiline
                    fullWidth
                    value={agent.marketingInstructions}
                    minRows={5}
                    required
                    onChange={(e) => {
                      const updatedAgent = { ...agent };
                      updatedAgent.marketingInstructions = e.currentTarget.value;
                      onAgentAppointmentChanged(updatedAgent);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  
                  <CurrencyField
                    label="Amount"
                    amount={agent.marketingAuthorisedAmount}
                    onChange={(amount) => {
                      const updatedAgent = { ...agent };
                      updatedAgent.marketingAuthorisedAmount = amount;
                      onAgentAppointmentChanged(updatedAgent);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormInputDatePicker
                    control={control}
                    label="When Payable"
                    name="marketingCostsDueDate"
                    required
                  />
                </Grid>
              </Grid>
            </Fragment>
          </Grid>
        </Box>

        <Box
          className="section-2"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 4,
            borderBottom: "1px solid silver",
            paddingBottom: 5,
          }}
        >
          <Typography variant="h6" component="span" className="roboto-bold">
            Section 2 - Repairs and maintenance
          </Typography>
          <Box>
            <Typography variant="body2">
              <i>(if applicable)</i>
            </Typography>
            <Typography variant="h6">Residential Sales</Typography>
            <Typography variant="body2">
              The maximum value of repairs and maintenance to be paid by the
              agent without prior approval by the client is
            </Typography>
          </Box>
          <Grid>
            {/* <FormInputNumeric
              control={control}
              label="Amount"
              name="repairsAndMaintenanceAuthorisedAmount"
            /> */}
            <CurrencyField
              label="Amount"
              amount={agent.repairsAndMaintenanceAuthorisedAmount}
              onChange={(amount) => {
                const updatedAgent = { ...agent };
                updatedAgent.repairsAndMaintenanceAuthorisedAmount = amount;
                onAgentAppointmentChanged(updatedAgent);
              }}
            />
          </Grid>
          <Typography variant="h6">Property management</Typography>
          <Typography variant="body2">Routine repairs</Typography>
          <Typography variant="body2">
            The maximum value of repairs and maintenance to be paid by the agent
            without prior approval by the client is
          </Typography>
          <Grid>
          <CurrencyField
              label="Amount"
              amount={agent.repairsAndMaintenancePropertyAuthorisedAmount}
              onChange={(amount) => {
                const updatedAgent = { ...agent };
                updatedAgent.repairsAndMaintenancePropertyAuthorisedAmount = amount;
                onAgentAppointmentChanged(updatedAgent);
              }}
            />
          </Grid>
        </Box>
        <Box></Box>

        <Box
          className="section-3"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 4,
            borderBottom: "1px solid silver",
            paddingBottom: 5,
          }}
        >
          <Typography variant="h6" component="span" className="roboto-bold">
            Section 3 - Fees
          </Typography>
          <Box>
            <Typography variant="body2">
              <i>Description of fees and charges.</i> <br />
              <i>
                The agent may either complete this section or attach annexures.
              </i>
            </Typography>
          </Box>
          <Grid>
            <FeesDataGrid
              fees={fees}
              onFeeChanged={onFeeChanged}
              onFeeDeleted={onFeeDeleted}
              onFeeAdded={onFeeAdded}
            />
          </Grid>
        </Box>

        <Box
          className="section-4"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h6" component="span" className="roboto-bold">
            Section 4 - Agent Benefits
          </Typography>
          <Box>
            <Typography variant="body1">
              Agent’s rebate, discount, commission or benefit incurred in the
              provision of or performance of the service
            </Typography>
          </Box>
          <Grid>
            <BenefitToAgentDataGrid
              benefitToAgent={benefits}
              onBenefitToAgentAdded={onBenefitToAgentAdded}
              onBenefitToAgentChanged={onBenefitToAgentChanged}
              onBenefitToAgentDeleted={onBenefitToAgentDeleted}
            />
          </Grid>
        </Box>
      </Box>
    </FormWrapper>
  );
};

export default Authorisation;
