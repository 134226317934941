import { FC, useCallback, useEffect } from "react";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  Box,
  Grid,
  Typography,
  debounce,
} from "@mui/material";
import AddressLabel from "../../../components/address-label";
import FormInputText from "../../../components/form-input-text";
import * as yup from "yup";

import type { AgentAppointment, Property } from "../../../generated";
import { OnAgentAppointmentChanged } from "../../../components/events";
import LCCard from "../../../components/cards/lc-card";
import FormWrapper from "./form-wrapper";

export type OnPropertyChanged = (property: Property) => void;

// const presentUseOptions = [
//   "Vacant land",
//   "Dwelling",
//   "Multi Unit",
//   "Flats",
//   "Guest House Private Hotel",
//   "Farming",
//   "Industrial",
//   "Commercial",
//   "Other",
// ];

export interface PropertyProps {
  property: Property;
  agentAppointment: AgentAppointment;
  onPropertyChanged: OnPropertyChanged;
  onAgentAppointmentChanged: OnAgentAppointmentChanged;
}

const Property: FC<PropertyProps> = ({
  agentAppointment,
  property,
  onAgentAppointmentChanged,
  onPropertyChanged,
}) => {
  const propertySchema = yup.object<Property>({
    lot: yup.string().required(),
    plan: yup.string().required(),
    titleReference: yup.string().required(),
  });

  const agentAppointmentSchema = yup.object<AgentAppointment>({});

  const {
    handleSubmit: agentAppointmentHandleSubmit,
    control: agentAppointmentControl,
    formState: agentAppointmentFormState,
    reset: agentAppointmentReset,
    trigger: agentAppointmentTrigger,
  } = useForm<AgentAppointment>({
    defaultValues: agentAppointment,
    resolver: yupResolver(agentAppointmentSchema),
  });

  const {
    handleSubmit: propertyHandleSubmit,
    control: propertyControl,
    formState: propertyFormState,
    reset: propertyReset,
    trigger: propertyTrigger,
  } = useForm<Property>({
    defaultValues: property,
    resolver: yupResolver(propertySchema),
  });

  useEffect(() => {
    agentAppointmentReset(agentAppointment);
    agentAppointmentTrigger();
  }, [agentAppointment]);

  useEffect(() => {
    propertyReset(property);
    propertyTrigger();
  }, [property]);

  const onAgentAppointmentSubmit: SubmitHandler<AgentAppointment> = (data) => {
    onAgentAppointmentChanged(data);
  };

  const onPropertySubmit: SubmitHandler<Property> = (data) => {
    onPropertyChanged(data);
  };

  const watchedAgentAppointmentData = useWatch({
    control: agentAppointmentControl,
    defaultValue: agentAppointment,
  });

  const watchedPropertyData = useWatch({
    control: propertyControl,
    defaultValue: property,
  });

  const debouncedAgentAppointmentSave = useCallback(
    debounce(() => {
      console.log("Saving agent appointment data");
      agentAppointmentHandleSubmit(onAgentAppointmentSubmit)();
    }, 1000),
    []
  );

  const debouncedPropertySave = useCallback(
    debounce(() => {
      console.log("Saving property data");
      propertyHandleSubmit(onPropertySubmit)();
    }, 1000),
    []
  );

  useDeepCompareEffect(() => {
    if (agentAppointmentFormState.isDirty) {
      debouncedAgentAppointmentSave();
    }
  }, [watchedAgentAppointmentData]);

  useDeepCompareEffect(() => {
    if (propertyFormState.isDirty) {
      debouncedPropertySave();
    }
  }, [watchedPropertyData]);

  const notes = [
    {
      title: "Note:",
      items: [
        "Please provide details of the property, land, or business as appropriate.",
        "Annexures detailing multiple properties may be attached if required.",
      ],
    },
  ];

  return (
    <FormWrapper
      title="Part 3 - Details of property or business that is to be sold, let, purchased, or managed"
      notes={notes}
    >
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Box
            sx={{
              width: "500px",
            }}
          >
            <LCCard
              title={"Property Address"}
              titleColor="#000"
              txtColor="#000"
              bgColor="#CDC5B4"
              circleElementBefore="#8f8c87"
              circleElementAfter="#dfd5bf"
            >
              <AddressLabel property={property} />
            </LCCard>
          </Box>

          <Grid item xs={12} sm={12} lg={12}>
            <Typography variant="h5">Description</Typography>
          </Grid>
          <Grid item xs={12}>
{/*             
            <Autocomplete
              id="description"
              freeSolo
              value={property.description ? property.description : ""}
              inputValue={property.description ? property.description : ""}
              options={presentUseOptions}
              onInputChange={(
                _event: any,
                newInputValue: string | undefined
              ) => {
                const newProperty = { ...property };
                newProperty.description = newInputValue!;
                onPropertyChanged(newProperty);
              }}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Present Use"
                  variant="filled"
                />
              )}
            /> */}
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormInputText
              control={propertyControl}
              name="lot"
              label="Lot"
              required
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormInputText
              control={propertyControl}
              name="plan"
              label="Plan"
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInputText
              control={propertyControl}
              name="titleReference"
              label="Title Reference"
              required
            />
          </Grid>
        </Grid>
      </Box>
    </FormWrapper>
  );
};

export default Property;
