import React from "react";
import { useParams } from "react-router-dom";
import OfferStepperView from "./offer-stepper-view";

const OfferStepperPage: React.FC = () => {
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const offerId = Number(params.offerId);

  return <OfferStepperView workspaceId={workspaceId} offerId={offerId} />;
};

export default OfferStepperPage;
