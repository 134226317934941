import React, { useEffect, useState } from "react";
import ClientDetails from "./components/client-details";
import {
  AgentAppointmentStatus,
  agentAppointmentChanged,
  agentAppointmentState,
  licenceeAgentChanged,
  licenceeChanged,
  participantChanged,
  propertyUpdated,
} from "./agent-appointment-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  AgentAppointment,
  Participant,
  Property as ApiProperty,
} from "../../generated";
import LicenceeDetails from "./components/licencee-details";
import Property from "./components/property";
import Appointment from "./components/appointment";
import PropertySale from "./components/property-sale";
import Commission from "./components/commission";
import Authorisation from "./components/authorisation";
import { useParams } from "react-router-dom";
import {
  addBenefitToAgent,
  addFee,
  addSeller,
  deleteBenefitToAgent,
  deleteFee,
  deleteParticipant,
  getAgentAppointment,
  getAgentAppointmentPDF,
  saveProperty,
  sendAgentAppointmentForSigning,
  updateAgentAppointment,
  updateBenefitToAgent,
  updateFee,
  updateParticipant,
} from "./thunks";
import { useDebounce } from "usehooks-ts";
import Generate from "./components/generate";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import {
  validateClientDetails,
  validateLicenceeDetails,
  validateProperty,
  validateAppointment,
  validatePropertySale,
  validateAuthorisation,
} from "../..//utils/validationUtils";
import ErrorList from "./components/error-list";
import StatusMessage from "./components/status-message";
import { Box } from "@mui/material";

const AgentAppointmentPage: React.FC = () => {
  const state = useAppSelector(agentAppointmentState);
  const params = useParams();
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState<{ message: string; step: number }[]>([]);
  // const steps = [
  //   "Client details",
  //   "Licencee details",
  //   "Property",
  //   "Appointment",
  //   "Sale",
  //   "Commission",
  //   "Authorisation",
  //   "Review",
  // ];

  useEffect(() => {
    if (activeStep == 7) {
      const clientErrors = validateClientDetails(state.clients, 0);
      const licenceeErrors = validateLicenceeDetails(
        state.licencee,
        state.licenceeAgent,
        1
      );
      const propertyErrors = validateProperty(state.property, 2);
      const appointmentErrors = validateAppointment(state.agentAppointment, 3);
      const propertySaleErrors = validatePropertySale(
        state.agentAppointment,
        4
      );
      // const commissionErrors = validateCommission(state.fees, state.benefits);
      const authorisationErrors = validateAuthorisation(
        state.agentAppointment,
        6
      );
      const toValidate = [
        ...clientErrors,
        ...licenceeErrors,
        ...propertyErrors,
        ...appointmentErrors,
        ...propertySaleErrors,
        // ...commissionErrors,
        ...authorisationErrors,
      ];

      const allErrors = toValidate
        .filter((error) => error !== null)
        .map((error) => ({
          message: error,
          step: parseInt(error.match(/Part (\d+)/)?.[1] ?? "0"),
        }));
      setErrors(allErrors);
      dispatch(getAgentAppointmentPDF(workspaceId));
    }
  }, [activeStep]);

  const workspaceId = parseInt(params["workspaceId"] as string);

  const debounceClients = useDebounce<Array<Participant> | undefined>(
    state.clients,
    1000
  );

  const debounceLicencee = useDebounce<Participant>(state.licencee, 1000);
  const debounceLicenceeAgent = useDebounce<Participant>(
    state.licenceeAgent,
    1000
  );
  const debounceAgentAppointment = useDebounce<AgentAppointment>(
    state.agentAppointment,
    1000
  );
  const debounceProperty = useDebounce<ApiProperty>(state.property, 1000);

  useEffect(() => {
    if (!state.agentAppointment || workspaceId != state.agentAppointment.id) {
      dispatch(getAgentAppointment(workspaceId));
    }
  }, [workspaceId]);

  useEffect(() => {
    if (state.status == AgentAppointmentStatus.dirty) {
      dispatch(
        saveProperty({ workspaceId: workspaceId, property: state.property })
      );
    }
  }, [debounceProperty]);

  useEffect(() => {
    if (state.status == AgentAppointmentStatus.dirty) {
      state.clients.forEach((c) => {
        dispatch(
          updateParticipant({
            workspaceId: workspaceId,
            participant: c,
          })
        );
      });
    }
  }, [debounceClients]);

  useEffect(() => {
    if (state.status == AgentAppointmentStatus.dirty) {
      dispatch(
        updateParticipant({
          workspaceId: workspaceId,
          participant: state.licenceeAgent,
        })
      );
    }
  }, [debounceLicenceeAgent]);

  useEffect(() => {
    if (state.status == AgentAppointmentStatus.dirty) {
      dispatch(
        updateParticipant({
          workspaceId: workspaceId,
          participant: state.licencee,
        })
      );
    }
  }, [debounceLicencee]);

  useEffect(() => {
    if (state.status == AgentAppointmentStatus.dirty) {
      dispatch(
        updateAgentAppointment({
          workspaceId: workspaceId,
          agentAppointment: state.agentAppointment,
        })
      );
    }
  }, [debounceAgentAppointment]);

  useEffect(() => {
    if (activeStep == 7) {
      dispatch(getAgentAppointmentPDF(workspaceId));
    }
  }, [activeStep]);

  // function onStepChanged(step: number): void {
  //   if (activeStep == step) {
  //     return;
  //   }
  //   setActiveStep(step);
  // }

  function onClientAdded(): void {
    dispatch(addSeller(workspaceId));
  }

  function onClientChanged(client: Participant): void {
    dispatch(participantChanged(client));
  }

  function onClientRemoved(client: Participant): void {
    dispatch(
      deleteParticipant({ workspaceId: workspaceId, participantId: client.id! })
    );
  }

  // function onParticipantChanged(participant: Participant): void {
  //   dispatch(participantChanged(participant));
  // }

  function onAgentAppointmentChanged(agent: AgentAppointment): void {
    dispatch(agentAppointmentChanged(agent));
  }

  function onPropertyChanged(property: Property): void {
    dispatch(propertyUpdated(property));
  }

  function handleSendAgentAppointment() {
    dispatch(sendAgentAppointmentForSigning(workspaceId));
  }

  function handleErrorClick(step: number) {
    setActiveStep(step === 0 ? step : step - 1);
  }

  console.log("step", activeStep);

  return (
    <Card
      sx={{
        boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        overflow: "unset",
        background: "#fff",
      }}
    >
      <CardHeader title="Agent Appointment" />
      <CardContent>
        {activeStep < 9 ? (
          <Box
            sx={{
              position: "sticky",
              background: "#fff",
              paddingBottom: "1px",
              top: 0,
              zIndex: 1000,
            }}
          >
            {/* <KonveiStepper
              steps={steps}
              activeStep={activeStep}
              onStepChanged={onStepChanged}
            /> */}
          </Box>
        ) : (
          <></>
        )}

        {activeStep == 0 ? (
          <ClientDetails
            clients={state.clients}
            onAdded={onClientAdded}
            onChanged={onClientChanged}
            onRemoved={onClientRemoved}
          />
        ) : null}
        {activeStep == 1 ? (
          <LicenceeDetails
            agentAppointment={state.agentAppointment}
            licencee={state.licencee}
            onAgentAppointmentChanged={onAgentAppointmentChanged}
            onLicenceeChanged={(licencee) =>
              dispatch(licenceeChanged(licencee))
            }
            sellerAgent={state.licenceeAgent}
            onLicenceeAgentChanged={(p) => dispatch(licenceeAgentChanged(p))}
          />
        ) : null}
        {activeStep == 2 ? (
          <Property
            property={state.property}
            onPropertyChanged={onPropertyChanged}
            agentAppointment={state.agentAppointment}
            onAgentAppointmentChanged={onAgentAppointmentChanged}
          />
        ) : null}
        {activeStep == 3 ? (
          <Appointment
            agent={state.agentAppointment}
            onAgentChanged={onAgentAppointmentChanged}
          />
        ) : null}
        {activeStep == 4 ? (
          <PropertySale
            agent={state.agentAppointment}
            onAgentChanged={onAgentAppointmentChanged}
          />
        ) : null}
        {activeStep == 5 ? (
          <Commission
            agentAppointment={state.agentAppointment}
            fees={state.fees}
            benefits={state.benefits}
            onFeeAdded={() => {}}
            onFeeChanged={() => {}}
            onFeeDeleted={() => {}}
            onBenefitToAgentAdded={() => {}}
            onBenefitToAgentChanged={() => {}}
            onBenefitToAgentDeleted={() => {}}
            onAgentAppointmentChanged={onAgentAppointmentChanged}
          />
        ) : null}
        {activeStep == 6 ? (
          <Authorisation
            agentAppointment={state.agentAppointment}
            fees={state.fees}
            benefits={state.benefits}
            onBenefitToAgentAdded={() => {
              dispatch(addBenefitToAgent(workspaceId));
            }}
            onBenefitToAgentChanged={(b) => {
              dispatch(
                updateBenefitToAgent({
                  workspaceId: workspaceId,
                  benefitToAgent: b,
                })
              );
            }}
            onBenefitToAgentDeleted={(b) => {
              dispatch(
                deleteBenefitToAgent({
                  workspaceId: workspaceId,
                  benefitToAgent: b,
                })
              );
            }}
            onFeeAdded={() => dispatch(addFee(workspaceId))}
            onFeeChanged={(fee) => dispatch(updateFee({ workspaceId, fee }))}
            onFeeDeleted={(fee) => dispatch(deleteFee({ workspaceId, fee }))}
            onAgentAppointmentChanged={onAgentAppointmentChanged}
          />
        ) : null}
        {activeStep == 7 ? (
          <Grid
            container
            sx={{ marginTop: 2 }}
            justifyContent={"center"}
            spacing={2}
          >
            <StatusMessage
              status={state.status}
              handleSendAgentAppointment={handleSendAgentAppointment}
            />
            <Grid item sx={{ width: "700px" }}>
              {errors.length > 0 ? (
                <ErrorList
                  errors={errors}
                  handleErrorClick={handleErrorClick}
                />
              ) : (
                <StatusMessage
                  status={state.status}
                  handleSendAgentAppointment={handleSendAgentAppointment}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Generate
                url={state.pdf!}
                fileName={state.pdfName!}
                isLoading={
                  state.status != AgentAppointmentStatus.pdfReadyToDownload &&
                  state.status != AgentAppointmentStatus.sentForSigning
                }
              />
            </Grid>
          </Grid>
        ) : null}
        {/* <Grid container>
          {!isLargeDown ? (
            <StepperControls
              activeStep={activeStep}
              onStepChanged={onStepChanged}
              steps={steps.length}
            ></StepperControls>
          ) : (
            <></>
          )}
        </Grid> */}
      </CardContent>
    </Card>
  );
};

export default AgentAppointmentPage;
