import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getDocument } from "./thunks";

export enum ViewDocumentStatus {
  initial,
  loading,
  ready,
}

export interface ViewDocumentState {
  status: ViewDocumentStatus;
  url: string;
}

const initialState: ViewDocumentState = {
  status: ViewDocumentStatus.initial,
  url: "",
};

export const viewDocumentSlice = createSlice({
  name: "viewDocument",
  initialState,
  reducers: {},
  extraReducers(builder) {
    getDocumentReducers(builder);
  },
});

function getDocumentReducers(
  builder: ActionReducerMapBuilder<ViewDocumentState>
) {
  builder.addCase(getDocument.pending, (state) => {
    state.status = ViewDocumentStatus.loading;
  });
  builder.addCase(getDocument.fulfilled, (state, action) => {
    console.log(action.payload);
    state.url = action.payload;
    state.status = ViewDocumentStatus.ready;
  });
}

export const {} = viewDocumentSlice.actions;
export default viewDocumentSlice.reducer;
export const viewDocumentState = (state: RootState) => state.viewDocument;
