import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Box } from "@mui/material";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { saveContract } from "../thunks";

const SafetyAlarmsAndSwitches: React.FC = ({}) => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();

  function onChanged(event: ChangeEvent<HTMLInputElement>): void {
    const updatedContract = { ...state.contract };
    switch (event.currentTarget.name) {
      case "hasSafetySwitches":
        updatedContract.hasSafetySwitches = event.currentTarget.value == "true";
        break;
      case "hasSmokeAlarms":
        updatedContract.hasSmokeAlarms = event.currentTarget.value == "true";
        break;
    }
    dispatch(saveContract(updatedContract));
  }

  return (
    <FormWrapper title="Part 8 - Safety Switches and Alarms">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} lg={12}>
            <Typography variant="body1">
              Does the seller give notice to the buyer that an approved safety
              switch for the general purpose socket outlets as been installed?
            </Typography>
          </Grid>
          <Grid item sm={12} lg={12}>
            <RadioGroup
              value={
                state.contract.hasSafetySwitches ? state.contract.hasSafetySwitches : false
              }
              id="hasSafetySwitches"
              name="hasSafetySwitches"
              onChange={onChanged}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>

          <React.Fragment>
            <Grid item sm={12} lg={12}>
              <Typography variant="body1">
                Does the seller give notice to the buyer that smoke alarms
                complying with the smoke alarm requirement provision have been
                installed?
              </Typography>
            </Grid>
            <Grid item sm={12} lg={12}>
              <RadioGroup
                value={
                  state.contract.hasSmokeAlarms ? state.contract.hasSmokeAlarms : false
                }
                id="hasSmokeAlarms"
                onChange={onChanged}
                name="hasSmokeAlarms"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
          </React.Fragment>
        </Grid>
      </Box>
    </FormWrapper>
  );
};

export default SafetyAlarmsAndSwitches;
