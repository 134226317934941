import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Contract,  } from "../../generated";
import { initialise } from "./thunks";
import { getRoles } from "../../app/authorisation";

export enum ContractsStatus {
  initial,
  ready,
}
interface MattersState {
  status: ContractsStatus;
  contracts: Contract[];
  roles: Array<string>;

}

const initialState: MattersState = {
  status: ContractsStatus.initial,
  contracts: [],
  roles: []
};
export const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    initialiseFulfilled(builder);
  }
});

function initialiseFulfilled(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(initialise.fulfilled, (state) => {
    
   // state.contracts = action.payload;
    state.contracts.sort((a,b) => {
      return Date.parse(a.createdAt!) - Date.parse(b.createdAt!);
    })
    state.status = ContractsStatus.ready;
    state.roles = getRoles();
  });
}

export default contractsSlice.reducer;
export const contractsState = (state: RootState) => state.contracts;


