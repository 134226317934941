import { createAsyncThunk } from "@reduxjs/toolkit";

import { addressSearchApi, teamsApi, workspacesApi } from "../../api";
import {
  FlattenedAddress,
  PhysicalAustralianAddress,
  Property,
  TeamParticipant,
} from "../../generated";

export const lookupAddress = createAsyncThunk<Array<FlattenedAddress>, string>(
  "createWorkspace/lookupAddress",
  async (address) => {
    const response = await addressSearchApi.searchAddress(address);
    return response.data.items!;
  }
);

export const getAddress = createAsyncThunk<PhysicalAustralianAddress, string>(
  "createWorkspace/getAddress",
  async (cid) => {
    const response = await addressSearchApi.getAddress(cid);
    return response.data;
  }
);


export interface GetWorkspaceArgs {
  cid: string;
  agentId: string;

}

export const createWorkspace = createAsyncThunk<number, GetWorkspaceArgs>(
  "createWorkspace/createWorkspace",
  async ({cid, agentId}) => {
    const deets = await addressSearchApi.getAddress(cid);

    const property: Property = {
      streetAddress1: generateStreetAddress1(deets.data),
      locality: deets.data.structured.locality!.name,
      postCode: deets.data.structured.postcode,
      stateOrTerritory: deets.data.structured.state?.abbreviation,

      lot: deets.data.structured.lotNumber?.number?.toString(),
    };
    const result = await workspacesApi.postWorkspace({property: property, agentId: agentId});
    return result.data.id!;
  }
);

export const getTenancyUsers = createAsyncThunk<Array<TeamParticipant>, void>(
  "createWorkspace/getUsers",
  async () => {
    const users = await teamsApi.getUsers();
    

    
    return users.data.users!;
  }
);

const generateStreetAddress1 = (address: PhysicalAustralianAddress): string => {
  let streetAddress1 = "";
  if (address.structured.buildingName) {
    streetAddress1 = `${address.structured.buildingName},`;
  }
  if (address.structured.flat?.number) {
    streetAddress1 = ` ${streetAddress1}${address.structured.flat.type?.name} ${address.structured.flat?.number}, `;
  }

  if (address.structured.street?.name) {
    streetAddress1 = ` ${streetAddress1}${address.structured.number?.number} ${address.structured.street?.name} ${address.structured.street?.type?.name}`;
  }
  return streetAddress1;
};
