import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Contract,
  Participant,
  Property,
  Workflow,
  WorkspaceSummary,
} from "../../generated";
import { contractsApi, offersApi, propertyApi, workspacesApi } from "../../api";

export interface NewOfferResponse {
  offerId: number;
  workspaceId: number;
  buyerId: number;
  buyerSolicitorId: number;
}

export const newOffer = createAsyncThunk<NewOfferResponse, number>(
  "offerStepper/newOffer",
  async (workspaceId) => {
    const offerId = await offersApi.postOffer(workspaceId, {
      status: "current",
      hasEncumbrances: false,
      hasNeighbourhoodDisputes: false,
      hasPool: false,
      hasPoolCertificate: false,
      hasSafetySwitches: false,
      hasSellerSolicitor: false,
      hasSmokeAlarms: false,
      hasTenant: false,
      id: 0,
    });
    const buyerId = await offersApi.postOfferParticipant(
      workspaceId,
      offerId.data.id!,
      {
        role: "buyer",
      }
    );
    const buyerSolicitorId = await offersApi.postOfferParticipant(
      workspaceId,
      offerId.data.id!,
      { role: "buyerSolicitor" }
    );

    const response: NewOfferResponse = {
      offerId: offerId.data.id!,
      workspaceId: workspaceId,
      buyerId: buyerId.data.id!,
      buyerSolicitorId: buyerSolicitorId.data.id!,
    };
    return response;
  }
);

export interface SaveOfferArgs {
  workspaceId: number;
  offer: Contract;
}
export const saveOffer = createAsyncThunk<void, SaveOfferArgs>(
  "offerStepper/saveOffer",
  async ({ workspaceId, offer }) => {
    await offersApi.putOffer(workspaceId, offer.id!, offer);
  }
);

// export interface OfferArgs {
//   workspaceId: number;
//   offerId: number;
// }
export const inviteBuyers = createAsyncThunk<void, OfferArgs>(
  "offerStepper/inviteBuyers",
  async ({ workspaceId, offerId }) => {
    offersApi.inviteBuyers(workspaceId, offerId);
  }
);

export const cancelOffer = createAsyncThunk<void, OfferArgs>(
  "offerStepper/cancelOffer",
  async ({ workspaceId, offerId }) => {
    offersApi.deleteOffer(workspaceId, offerId);
  }
);
export interface GetOfferArgs {
  workspaceId: number;
  offerId: number;
}
export interface GetOfferResponse {
  contract: Contract;
  offer: Contract;
  buyers: Array<Participant>;
  property: Property;
  buyersSolicitor: Participant;
  workspace: WorkspaceSummary;
  workflow: Workflow;
}
export const getOffer = createAsyncThunk<GetOfferResponse, GetOfferArgs>(
  "offerStepper/getOffer",
  async (args) => {
    const contractPromise = contractsApi.getContract(args.workspaceId);
    const offerPromise = offersApi.getOffer(args.workspaceId, args.offerId);
    const propertyPromise = propertyApi.getProperty(args.workspaceId);
    const workflowPromise = workspacesApi.getWorkflow(args.workspaceId);
    const participantsPromise = offersApi.getOfferParticipants(
      args.workspaceId,
      args.offerId
    );
    const workspacePromise = workspacesApi.getWorkspace(args.workspaceId);
    const [contract, offer, property, participants, workspace, workflow] =
      await Promise.all([
        contractPromise,
        offerPromise,
        propertyPromise,
        participantsPromise,
        workspacePromise,
        workflowPromise,
      ]);

    const response: GetOfferResponse = {
      offer: offer.data,
      contract: contract.data,
      property: property.data,
      buyers: participants.data.items!.filter((x) => x.role == "buyer"),
      buyersSolicitor: participants.data.items!.filter(
        (x) => x.role == "buyerSolicitor"
      )[0],
      workspace: workspace.data,
      workflow: workflow.data,
    };
    return response;
  }
);

export interface UpdateParticipantArgs {
  offerId: number;
  contractId: number;
  participant: Participant;
}

export const updateParticipant = createAsyncThunk<void, UpdateParticipantArgs>(
  "offerStepper/updateParticipant",
  async (args) => {
    await offersApi.putOfferParticipant(
      args.contractId,
      args.offerId,
      args.participant.id!,
      args.participant
    );
  }
);

export const addBuyer = createAsyncThunk<Participant, OfferArgs>(
  "offerStepper/addBuyer",
  async (args) => {
    const buyer: Participant = {
      role: "buyer",
    };
    const response = await offersApi.postOfferParticipant(
      args.workspaceId,
      args.offerId,
      buyer
    );
    buyer.id = response.data.id;

    return buyer;
  }
);

export interface OfferArgs {
  offerId: number;
  workspaceId: number;
}
export interface OfferParticipantArgs {
  offerId: number;
  contractId: number;
  participantId: number;
}
export const deleteBuyer = createAsyncThunk<number, OfferParticipantArgs>(
  "offerStepper/deleteBuyer",
  async ({ contractId, offerId, participantId }) => {
    await offersApi.deleteOfferParticipant(contractId, offerId, participantId);
    return participantId;
  }
);

export const deleteOffer = createAsyncThunk<void, OfferArgs>(
  "offerStepper/deleteOffer",
  async ({ offerId, workspaceId }) => {
    await offersApi.deleteOffer(workspaceId, offerId);
  }
);

export const updateContract = createAsyncThunk<void, Contract>(
  "offerStepper/updateContract",
  async (contract) => {
    await contractsApi.putContract(contract.id, contract);
  }
);
