import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  contractsApi,
  participantsApi,
  propertyApi,
  teamsApi,
  workspacesApi,
} from "../../api";
import {
  Annexure,
  Contract,
  Participant,
  Property,
  PutDocumentDocumentTypeEnum,
  WorkspaceSummary,
} from "../../generated";
import { authority, client_id, konvei_api } from "../../constants";
import { User } from "oidc-client-ts";
import axios from "axios";

export interface ContractResponse {
  contract: Contract;
  workspace: WorkspaceSummary;
  participants: Array<Participant>;
  annexures: Array<Annexure>;
}

export interface AnnexuresResponse {
  annexures: Array<Annexure>;
}

export const getContract = createAsyncThunk<ContractResponse, number>(
  "contractStepper/getContract",
  async (workspaceId) => {
    const workspacePromise = workspacesApi.getWorkspace(workspaceId);
    const contractResponse = contractsApi.getContract(workspaceId);
    const documentsPromise = contractsApi.getContractAnnexures(workspaceId);

    const contractParticipantResponse =
      participantsApi.getParticipants(workspaceId);

    const [workspace, contract, participants, annexures] = await Promise.all([
      workspacePromise,
      contractResponse,
      contractParticipantResponse,
      documentsPromise,
    ]);

    const resp: ContractResponse = {
      workspace: workspace.data,
      contract: contract.data,
      participants: participants.data.items!,
      annexures: annexures.data.items!,
    };

    return resp;
  }
);

export const getDocuments = createAsyncThunk<AnnexuresResponse, number>(
  "contractStepper/getDocuments",
  async (workspaceId) => {
    const documents = await contractsApi.getContractAnnexures(workspaceId);

    const resp: AnnexuresResponse = {
      annexures: documents.data.items,
    };

    return resp;
  }
);

export interface CreateContractResponse {
  contract: Contract;
  seller: Participant;
  solicitor: Participant;
  agent: Participant;
}
export const createContract = createAsyncThunk<CreateContractResponse, number>(
  "contractStepper/createContract",
  async () => {
    const contract: Contract = {
      id: 1,
      status: "",
      hasEncumbrances: false,
      hasNeighbourhoodDisputes: false,
      hasPool: false,
      hasPoolCertificate: false,
      hasSafetySwitches: false,
      hasSellerSolicitor: false,
      hasSmokeAlarms: false,
      hasTenant: false,
    };

    const seller: Participant = {
      id: 0,
      role: "seller",
    };
    const sellerSolicitor: Participant = {
      id: 0,
      role: "sellerSolicitor",
    };

    const agent: Participant = {
      id: 0,
      role: "sellerAgent",
    };
    // const t1 = participantsApi.postParticipant(workspaceId, seller);

    // const t3 = participantsApi.postParticipant(workspaceId, agent);
    // const [sellerResp, sellerSolResp, agentResp] = await Promise.all([
    //   t1,
    //   t2,
    //   t3,
    // ]);

    const response: CreateContractResponse = {
      contract: contract,
      seller: seller,
      solicitor: sellerSolicitor,
      agent: agent,
    };
    return response;
  }
);

export const saveContract = createAsyncThunk<Contract, Contract>(
  "contractStepper/saveContract",
  async (contract) => {
    await contractsApi.putContract(contract.id, contract);
    return contract;
  }
);

export interface SavePropertyArgs {
  workspaceId: number;
  property: Property;
}

export const saveProperty = createAsyncThunk<void, SavePropertyArgs>(
  "contractStepper/saveProperty",
  async ({ workspaceId, property }) => {
    await propertyApi.putProperty(workspaceId, property);
  }
);

export interface UpdateContractParticipantArgs {
  workspaceId: number;
  participant: Participant;
}
export const saveContractParticipant = createAsyncThunk<
  Participant,
  UpdateContractParticipantArgs
>(
  "contractStepper/saveContractParticipant",
  async ({ workspaceId, participant }) => {
    await participantsApi.putParticipant(
      workspaceId,
      participant.id!,
      participant
    );
    return participant;
  }
);

export const addSeller = createAsyncThunk<number, number>(
  "contractStepper/addSeller",
  async (workspaceId) => {
    const participant: Participant = {
      role: "seller",
    };
    const response = await participantsApi.postParticipant(
      workspaceId,
      participant
    );
    return response.data.id!;
  }
);

export interface RemoveParticipantArgs {
  contractId: number;
  participantId: number;
}
export const removeSeller = createAsyncThunk<number, RemoveParticipantArgs>(
  "contractStepper/removeSeller",
  async ({ contractId: workspaceId, participantId }) => {
    await participantsApi.deleteParticipant(workspaceId, participantId);
    return participantId;
  }
);

export const getPdf = createAsyncThunk<string, number>(
  "contractStepper/getPdf",
  async (workspaceId) => {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${authority}:${client_id}`
    );
    const user = User.fromStorageString(oidcStorage!);

    const response = await axios.get(
      `${konvei_api}/workspaces/${workspaceId}/contract/pdf`,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(response.data);

    return url;
  }
);

export const completeContractDrafting = createAsyncThunk<void, number>(
  "contractStepper/completeContractDrafting",
  async (workspaceId) => {
    await contractsApi.completeContractDrafting(workspaceId);
  }
);

export interface UploadAnnexureArgs {
  workspaceId: number;
  annexure: File;
}

export interface UpdateAnnexureArgs {
  workspaceId: number;
  annexureId: number;
  position: number;
}

export interface DeleteAnnexureArgs {
  workspaceId: number;
  annexureId: number;
}

export const uploadAnnexure = createAsyncThunk<void, UploadAnnexureArgs>(
  "contractStepper/uploadAnnexure",
  async ({ workspaceId, annexure }) => {
    await contractsApi.addAnnexure(
      workspaceId,
      annexure.name,
      PutDocumentDocumentTypeEnum.Annexure,
      annexure
    );
  }
);

export const updateAnnexure = createAsyncThunk<void, UpdateAnnexureArgs>(
  "contractStepper/updateAnnexure",
  async ({ workspaceId, annexureId, position }) => {
    await contractsApi.updateAnnexurePosition(
      workspaceId,
      annexureId,
      position
    );
  }
);

export const deleteAnnexure = createAsyncThunk<void, DeleteAnnexureArgs>(
  "contractStepper/deleteAnnexure",
  async ({ workspaceId, annexureId }) => {
    await contractsApi.deleteAnnexure(workspaceId, annexureId);
  }
);

export const getCurrentUserDetails = createAsyncThunk<Participant, void>(
  "contractStepper/getCurrentUserDetails",
  async () => {
    const response = await teamsApi.getMyDetails();
    return response.data.user
  }
);

