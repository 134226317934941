import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { OfferFormProps } from "./events";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";
import {
  currencyFromBaseUnit,
  currencyToBaseUnit,
} from "../../../helpers/helpers";
import { Box, useTheme } from "@mui/material";
import FormWrapper from "../../agent-appointment/components/form-wrapper";

const Price: React.FC<OfferFormProps> = ({ offer, onOfferChanged }) => {
  const theme = useTheme();
  const depositInitialDueOptions = ["1 day after contract signing"];
  const depositBalanceDueOptions = [
    "1 business day from satisfaction of finance condition",
    "1 business day from satisfaction of building and pest condition",
    "1 business day from unconditional date",
  ];
  //   const [balanceDeposit, setBalanceDeposit] = useState("");
  //   const [initialDeposit, setInitialDeposit] = useState("");

  return (
    <FormWrapper title="Part 2 - Price">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Price
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumericFormat
              id="price"
              name="price"
              value={currencyFromBaseUnit(offer.purchasePrice)}
              prefix="$ "
              variant="filled"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              customInput={TextField}
              label="Price"
              onValueChange={(value) => {
                const u = { ...offer };
                u.purchasePrice = currencyToBaseUnit(value.floatValue);
                onOfferChanged(u);
              }}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Deposits
            </Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} sm={6}>
              <NumericFormat
                id="depositInitial"
                name="depositInitial"
                value={currencyFromBaseUnit(offer.depositInitial)}
                prefix="$ "
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                customInput={TextField}
                label="Initial Deposit"
                onValueChange={(value) => {
                  const u = { ...offer };
                  u.depositInitial = currencyToBaseUnit(value.floatValue);
                  onOfferChanged(u);
                }}
                fullWidth
                size="small"
                variant="filled"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="depositInitialDue"
                fullWidth={true}
                value={offer.depositInitialDue ? offer.depositInitialDue : ""}
                inputValue={
                  offer.depositInitialDue ? offer.depositInitialDue : ""
                }
                onInputChange={(
                  _event: any,
                  newInputValue: string | undefined
                ) => {
                  const newOffer = { ...offer };
                  newOffer.depositInitialDue = newInputValue!;
                  onOfferChanged(newOffer);
                }}
                size="small"
                options={depositInitialDueOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Initial deposit due"
                    variant="filled"
                  />
                )}
                freeSolo
                autoComplete
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <NumericFormat
              id="depositBalance"
              name="depositBalance"
              value={currencyFromBaseUnit(offer.depositBalance)}
              prefix="$ "
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              customInput={TextField}
              label="Balance Deposit"
              onValueChange={(value) => {
                const u = { ...offer };
                u.depositBalance = currencyToBaseUnit(value.floatValue);
                onOfferChanged(u);
              }}
              fullWidth
              size="small"
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="depositBalanceDue"
              fullWidth={true}
              value={offer.depositBalanceDue ? offer.depositBalanceDue : ""}
              inputValue={
                offer.depositBalanceDue ? offer.depositBalanceDue : ""
              }
              onInputChange={(
                _event: any,
                newInputValue: string | undefined
              ) => {
                const newOffer = { ...offer };
                newOffer.depositBalanceDue = newInputValue!;
                onOfferChanged(newOffer);
              }}
              size="small"
              options={depositBalanceDueOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Balance deposit due"
                  variant="filled"
                />
              )}
              freeSolo
              autoComplete
            />
          </Grid>
        </Grid>
      </Box>
    </FormWrapper>
  );
};

export default Price;
