import { Participant } from "../../../generated";
import { OnParticipantChanged } from "../../../components/events";
import ClientDetail from "./client-detail";
import { Box } from "@mui/material";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { useParams } from "react-router-dom";
import { saveContractParticipant } from "../thunks";

export type OnAddBuyer = () => void;

export interface SellerDetailsProps {
  sellers: Array<Participant>;
  onChanged: OnParticipantChanged;
  onAdded: OnAddBuyer;
  onRemoved: OnParticipantChanged;
}
const SellerDetails: React.FC = ({
}) => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  

  function handleClientChanged(participant: Participant): void {
    dispatch(saveContractParticipant({
      participant: participant,
      workspaceId: workspaceId,
    }))
  }

  return (
    <FormWrapper title="Part 1 - Seller's Details">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        {state.sellers.map((client, index) => (
          <ClientDetail
            key={client.id}
            client={client}
            index={index}
            onChanged={handleClientChanged}
          />
        ))}
      </Box>
    </FormWrapper>
  );
};

export default SellerDetails;
