import * as yup from "yup";
import {
  AgentAppointment,
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../generated";

const participantSchema = yup
  .object<Participant>({
    firstName: yup.string().when(["participantType"], {
      is: (val: string) => val == ParticipantParticipantTypeEnum.Individual,
      then: () => yup.string().required(),
      otherwise: () => yup.string().notRequired(),
    }),
    lastName: yup.string().when(["participantType"], {
      is: (val: string) => val == ParticipantParticipantTypeEnum.Individual,
      then: () => yup.string().required(),
      otherwise: () => yup.string().notRequired(),
    }),
    organisationName: yup.string().when(["participantType"], {
      is: (val: string) =>
        val == ParticipantParticipantTypeEnum.Organisation ||
        val == ParticipantParticipantTypeEnum.Trust,
      then: () => yup.string().required(),
      otherwise: () => yup.string().notRequired(),
    }),
    abn: yup.string().when(["participantType"], {
      is: (participantType: string) =>
        participantType == ParticipantParticipantTypeEnum.Organisation ||
        participantType == ParticipantParticipantTypeEnum.Trust,
      then: () =>
        yup
          .string()
          .matches(/^(\d *?){11}$/, "must have 11 digits")
          .required(),
      otherwise: () => yup.string().notRequired(),
    }),
    acn: yup.string().when(["participantType"], {
      is: (participantType: string) =>
        participantType == ParticipantParticipantTypeEnum.Organisation,
      then: () =>
        yup
          .string()
          .matches(/^(\d *?){9}$/, "must have 9 digits")
          .required(),
      otherwise: () => yup.string().notRequired(),
    }),
    streetAddress1: yup.string().required(),
    locality: yup.string().required(),
    stateOrTerritory: yup.string().required(),
    postCode: yup.string().required(),
    phone: yup
      .string()
      .matches(/^(?:\+?61|0)[2-478](?:\d{8}|\d{9})$/, "not a phone number"),
    email: yup.string().email("invalid email").required(),
  })
  .required();

const agentAppointmentSchema = yup
  .object<AgentAppointment>({
    licenceeNumber: yup.string().required(),
    licenceeExpiryDate: yup.string().required(),
  })
  .required();

export { participantSchema, agentAppointmentSchema };
