import { FC } from "react";

import { Controller } from "react-hook-form";
import { FormInputProps } from "./events";
import CurrencyField from "./currency-field";

const FormInputNumeric: FC<FormInputProps> = ({
  name,
  label,
  control,
  required,
}) => {
  return (
    <Controller
      name={name}
      
      control={control}
      render={({ field: { onChange, value } }) => (
        <CurrencyField
          label={label}
          amount={value}
          required={required}
          onChange={(amount) => {
            onChange(amount);
          }}
        />
      )}
    />
  );
};

// const currencyToBaseUnit = (value: number | undefined): number | undefined => {
//   if (!value) return undefined;
//   return Math.floor(value! * 100);
// };

export default FormInputNumeric;
