import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { FC } from "react";
import PDFViewer from "../../../components/pdf-viewer";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "./generate.css";

export interface GenerateProps {
  url: string;
  fileName: string;
  isLoading: boolean;
}

const Generate: FC<GenerateProps> = ({ url, isLoading }) => {

  return (
    <Container>
      {isLoading ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress size={100}></CircularProgress>
        </Grid>
      ) : null}
      {!isLoading ? (
        <Grid
          container
          spacing={2}
          padding={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
         
          
          <Grid id="pdf" item padding={2} >
            <Paper square>
              <Grid item xs={12} className="generate-container">
                <PDFViewer url={url} />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      ) : null}
    </Container>
  );
};

export default Generate;
