import { FC } from "react";

import { Controller } from "react-hook-form";
import { FormInputProps } from "./events";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const FormInputDatePicker: FC<FormInputProps> = ({
  name,
  label,
  control,
  required,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <DatePicker
          label={label}
          value={dayjs(field.value)}
          onChange={(value) => {
            const d = value?.toISOString();
            field.onChange(d);
          }}
          slotProps={{
            textField: {
              size: "small",
              fullWidth: true,
              required,
              variant: "filled",
            },
          }}
        />
      )}
    />
  );
};

export default FormInputDatePicker;
