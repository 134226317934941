import { Grid, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import ParticipantNameForm from "../../../components/participant-name-form";
import ContactDetails from "../../../components/contact-details";
import AddressForm from "../../../components/address-form";
import { Participant1, TenantContact } from "../../../generated";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SettingsStatus, settingsState } from "../settings-slice";
import { getLicenceeDetails } from "../thunks";
import FormInputDatePicker from "../../../components/form-input-date-picker";
import FormInputText from "../../../components/form-input-text";

const Licencee: FC = () => {
  const state = useAppSelector(settingsState);
  const dispatch = useAppDispatch();

  const form = useForm<Participant1>({
    defaultValues: {
      organisationName: "",
      email: "",
      streetAddress1: "",
      stateOrTerritory: "",
      locality: "",
      postCode: "",
    },
  });

  const { control, setValue, reset } = form;

  const licenceeForm = useForm<TenantContact>({
    defaultValues: {licenceeExpiryDate: "", licenceeNumber:""}
  })

  useEffect(() => {
    if (state.licenceeStatus == SettingsStatus.initial) {
      dispatch(getLicenceeDetails());
    }
    if (state.licenceeStatus == SettingsStatus.ready) {
      reset(state.licencee.contact);
      licenceeForm.reset(state.licencee)
    }
  }, [state.licencee, state.licenceeStatus, reset]);

  // const onSubmitLicence = (participant: TenantContact) => {

  //   dispatch(updatelicenceeDetails(participant));
  // };

  // const onSubmit = (participant: Participant1) => {
  //   const n: TenantContact = {
  //     contact: participant,
  //     licenceeExpiryDate: state.licencee.licenceeExpiryDate,
  //     licenceeNumber: state.licencee.licenceeNumber,
  //   };
  //   dispatch(updatelicenceeDetails(n));
  // };

 // useAutoSave(form, onSubmit);
 // useAutoSave(licenceeForm, onSubmitLicence)

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Licencee</Typography>
        </Grid>
        <Grid item xs={12}>
          <ParticipantNameForm
            control={control}
            participantType={"organisation"}
            captureMiddleName={false}
            organisationLabel="Trading Name"
          ></ParticipantNameForm>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <FormInputText
            control={licenceeForm.control}
            label="Licence Number"
            name="licenceeNumber"
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormInputDatePicker
            control={licenceeForm.control}
            name={"licenceeExpiryDate"}
            label={"Licence Expiry"}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ContactDetails control={control}></ContactDetails>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">Address</Typography>
        </Grid>
        <AddressForm
          control={control}
          setValue={setValue}
          address={{ ...state.licencee.contact }}
        ></AddressForm>
      </Grid>
    </form>
  );
};

export default Licencee;
