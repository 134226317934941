import { FC, Fragment, useCallback, useEffect } from "react";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Box, FormGroup, Grid, Typography, debounce } from "@mui/material";
import AddressForm from "../../../components/address-form";
import ParticipantNameForm from "../../../components/participant-name-form";
import FormInputCheckbox from "../../../components/form-input-checkbox";
import FormInputText from "../../../components/form-input-text";
import ContactDetails from "../../../components/contact-details";
import FormInputDatePicker from "../../../components/form-input-date-picker";
import { AgentAppointment, Participant } from "../../../generated";
import {
  OnAgentAppointmentChanged,
  OnParticipantChanged,
} from "../../../components/events";
import { participantSchema } from "./schemas";
import * as yup from "yup";
import FormWrapper from "./form-wrapper";

export interface LicenceeDetailsProps {
  agentAppointment: AgentAppointment;
  licencee: Participant;
  sellerAgent: Participant;
  onLicenceeChanged: OnParticipantChanged;
  onLicenceeAgentChanged: OnParticipantChanged;
  onAgentAppointmentChanged: OnAgentAppointmentChanged;
}

const LicenceeDetails: FC<LicenceeDetailsProps> = ({
  agentAppointment,
  licencee,
  sellerAgent,
  onLicenceeAgentChanged,
  onAgentAppointmentChanged,
  onLicenceeChanged,
}) => {
  const licenceeSchema = yup
    .object({
      organisationName: yup.string().required(),
      abn: yup
        .string()
        .matches(/^(\d *?){11}$/, "must have 11 digits")
        .required(),
      acn: yup
        .string()
        .matches(/^(\d *?){9}$/, "must have 9 digits")
        .required(),
    })
    .required();

  const {
    handleSubmit: agentAppointmentHandleSubmit,
    control: agentAppointmentControl,
    formState: agentAppointmentFormState,
    reset: agentAppointmentReset,
  } = useForm<AgentAppointment>({
    defaultValues: agentAppointment,
    //resolver: yupResolver(agentAppointmentSchema),
  });

  const {
    handleSubmit: licenceeHandleSubmit,
    control: licenceeControl,
    formState: licenceeFormState,
    reset: licenceeReset,
    trigger: licenceeTrigger,
  } = useForm<any>({
    defaultValues: licencee,
    resolver: yupResolver(licenceeSchema),
  });

  const {
    handleSubmit: sellerAgentHandleSubmit,
    control: sellerAgentControl,
    formState: sellerAgentFormState,
    reset: sellerAgentReset,
    setValue: sellerAgentSetValue,
    trigger: sellerAgentTrigger,
  } = useForm<Participant>({
    defaultValues: sellerAgent,
    resolver: yupResolver(participantSchema),
  });

  useEffect(() => {
    agentAppointmentReset(agentAppointment);
  }, [agentAppointment]);

  useEffect(() => {
    licenceeReset(licencee);
    licenceeTrigger();
  }, [licencee]);

  useEffect(() => {
    sellerAgentReset(sellerAgent);
    sellerAgentTrigger();
  }, [sellerAgent]);

  const onAgentAppointmentSubmit: SubmitHandler<AgentAppointment> = (data) => {
    onAgentAppointmentChanged(data);
  };

  const onLicenceeSubmit: SubmitHandler<Participant> = (data) => {
    onLicenceeChanged(data);
  };

  const onSellerAgentSubmit: SubmitHandler<Participant> = (data) => {
    onLicenceeAgentChanged(data);
  };

  const watchedLicencee = useWatch({
    control: licenceeControl,
    defaultValue: licencee,
  });

  const watchedAgentAppointment = useWatch({
    control: agentAppointmentControl,
    defaultValue: agentAppointment,
  });

  const watchedSellerAgent = useWatch({
    control: sellerAgentControl,
    defaultValue: sellerAgent,
  });

  const agentAppointmentDebouncedSave = useCallback(
    debounce(() => {
      agentAppointmentHandleSubmit(onAgentAppointmentSubmit)();
    }, 1000),
    [agentAppointmentHandleSubmit, onAgentAppointmentSubmit]
  );

  const licenceeDebouncedSave = useCallback(
    debounce(() => {
      licenceeHandleSubmit(onLicenceeSubmit)();
    }, 1000),
    [licenceeHandleSubmit, onLicenceeSubmit]
  );

  const sellerAgentDebouncedSave = useCallback(
    debounce(() => {
      sellerAgentHandleSubmit(onSellerAgentSubmit)();
    }, 1000),
    [sellerAgentHandleSubmit, onSellerAgentSubmit]
  );

  useDeepCompareEffect(() => {
    if (agentAppointmentFormState.isDirty) {
      agentAppointmentDebouncedSave();
    }
  }, [
    watchedAgentAppointment,
    agentAppointmentDebouncedSave,
    agentAppointmentFormState.isDirty,
  ]);

  useDeepCompareEffect(() => {
    if (licenceeFormState.isDirty) {
      licenceeDebouncedSave();
    }
  }, [watchedLicencee, licenceeDebouncedSave, licenceeFormState.isDirty]);

  useDeepCompareEffect(() => {
    if (sellerAgentFormState.isDirty) {
      sellerAgentDebouncedSave();
    }
  }, [
    watchedSellerAgent,
    sellerAgentDebouncedSave,
    sellerAgentFormState.isDirty,
  ]);

  const notes = [
    {
      title: "Licensee Type:",
      items: [
        "More than one box may be ticked if appropriate.",
        "Annexures detailing conjuncting agents may be attached if required.",
      ],
    },
    {
      title: "Licensee Name:",
      items: [
        "Where a corporation licensee is to be appointed, state the corporation’s name and licence number.",
        "Where a sole trader is to be appointed, state the individual’s name and licence number.",
      ],
    },
  ];

  return (
    <FormWrapper title="Part 2 - Licencee Details" notes={notes}>
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Licencee Type</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <Box sx={{ display: "flex" }}>
                  <FormInputCheckbox
                    control={agentAppointmentControl}
                    name="isRealEstateAgent"
                    label="Real estate agent"
                  />
                  <FormInputCheckbox
                    control={agentAppointmentControl}
                    name="isLettingAgent"
                    label="Resident Letting Agent"
                  />
                  <FormInputCheckbox
                    control={agentAppointmentControl}
                    name="isPropertyAuctioneer"
                    label="Property Auctioneer"
                  />
                </Box>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Licencee</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Agent Name</Typography>
            </Grid>

            <Grid item xs={12}>
              <ParticipantNameForm
                control={sellerAgentControl}
                participantType="individual"
                captureMiddleName={false}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">Licencee</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                control={sellerAgentControl}
                name="tradingName"
                label="Trading name"
              />
            </Grid>


            <Grid item xs={12}>
              <ParticipantNameForm
                control={sellerAgentControl}
                participantType={sellerAgent.participantType!}
                captureMiddleName={false}
                organisationLabel="Licencee Name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormInputText
                control={sellerAgentControl}
                label="Licence Number"
                name="licenceeNumber"
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormInputDatePicker
                control={sellerAgentControl}
                name={"licenceeExpiryDate"}
                label={"Licence Expiry"}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Contact details</Typography>
            </Grid>
            <Grid item xs={12}>
              <ContactDetails control={sellerAgentControl} />
            </Grid>

            <AddressForm
              control={sellerAgentControl}
              setValue={sellerAgentSetValue}
              address={{ ...sellerAgent }}
            />
          </Grid>
        </Fragment>
      </Box>
    </FormWrapper>
  );
};

export default LicenceeDetails;
