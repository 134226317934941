import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { OfferDetail, Workflow, WorkspaceSummaryNew } from "../../generated";
import { RootState } from "../../app/store";

import { getOffers, getWorkflowState, getWorkspaces } from "./thunks";

export enum WorkspacesStatus {
  initial,
  loading,
  ready,
}

export interface IndexedWorkflowState extends Workflow {
  workspaceId: number;
}
interface WorkspacesState {
  status: WorkspacesStatus;
  workspaces: Array<WorkspaceSummaryNew>;
  workflowStates: Array<IndexedWorkflowState>;
  offers: OfferDetail[];
}

const initialState: WorkspacesState = {
  status: WorkspacesStatus.initial,
  workspaces: [],
  workflowStates: [],
  offers: [],
};

export const workspacesSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getWorkspacesReducer(builder);
    getWorkflowReducer(builder);
    getOfferStateReducer(builder);
  },
});

function getOfferStateReducer(
  builder: ActionReducerMapBuilder<WorkspacesState>
) {
  builder.addCase(getOffers.fulfilled, (state, action) => {
    state.offers.push(...action.payload);
  });
}
function getWorkspacesReducer(
  builder: ActionReducerMapBuilder<WorkspacesState>
) {
  builder.addCase(getWorkspaces.pending, (state) => {
    state.status == WorkspacesStatus.loading;
  });
  builder.addCase(getWorkspaces.fulfilled, (state, action) => {
    state.workspaces = action.payload;

    state.status == WorkspacesStatus.ready;
  });
}

function getWorkflowReducer(builder: ActionReducerMapBuilder<WorkspacesState>) {
  builder.addCase(getWorkflowState.pending, (state) => {
    state.status == WorkspacesStatus.loading;
  });
  builder.addCase(getWorkflowState.fulfilled, (state, action) => {
    const index = state.workflowStates.findIndex(
      (w) => w.workspaceId == action.payload.workspaceId
    );
    if (index == -1) {
      const item: IndexedWorkflowState = {
        ...action.payload.workflow,
        workspaceId: action.payload.workspaceId,
      };
      state.workflowStates.push(item);
    }
  });
}

// export const {} = workspacesSlice.actions;
export default workspacesSlice.reducer;
export const workspacesState = (state: RootState) => state.workspaces;
