import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import type {
  Contract,
  Workflow,
  WorkspaceSummary,
} from "../../../../generated";

import LCCard from "../../../../components/cards/lc-card";

export interface OfferActionProps {
  workspaceSummary: WorkspaceSummary;
  workflow: Workflow;
  offer: Contract;
}
const OfferAction: FC<OfferActionProps> = ({ offer }) => {
  const navigate = useNavigate();

  // function buildActions(): JSX.Element {
  //   return (
  //     <Fragment>
  //       <IconButton
  //         color="primary"
  //         onClick={() => setConfirmationDialogState(true)}
  //         sx={{
  //           "&:hover": {
  //             backgroundColor: "rgba(0,0,0,0)",
  //             "& .MuiTypography-root": {
  //               color: "#555",
  //             },
  //             "& .MuiSvgIcon-root": {
  //               color: "#555",
  //             },
  //           },
  //         }}
  //       >
  //         <DeleteForeverOutlinedIcon
  //           fontSize="large"
  //           sx={{ color: "#8F1219" }}
  //         />
  //         <Typography component="span" sx={{ color: "#8F1219" }}>
  //           Delete Offer
  //         </Typography>
  //       </IconButton>
  //       <ConfirmationDialog
  //         open={confirmationDialogState}
  //         message={`This cannot be undone. Continue?`}
  //         title="Delete offer"
  //         onClose={(result) => {
  //           if (result) {
  //             dispatch(
  //               deleteOffer({
  //                 workspaceId: workspaceSummary?.workspace?.id!,
  //                 offerId: offer.id!,
  //               })
  //             ).then(() => navigate(-1));
  //             setConfirmationDialogState(false);
  //           } else {
  //             setConfirmationDialogState(false);
  //           }
  //         }}
  //       />
  //     </Fragment>
  //   );
  // }

  return (
    <LCCard
      title={
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            variant="h6"
            color="#000"
            onClick={() => navigate(-1)}
          >
            Back to Offers
          </Link>
          <Typography variant="h6" color="text.primary">
            {offer.id}
          </Typography>
        </Breadcrumbs>
      }
      txtColor="#000"
      titleColor="#000"
      bgColor="#fff"
      sxCard={{ overflow: "visible" }}
    >
      <Outlet></Outlet>
    </LCCard>
  );
};

export default OfferAction;
