import { FC } from "react";
import { WorkspaceState } from "../workspace-slice";
import { OnOfferClicked } from "../../../components/events";
import DocumentsTable, {
  OnDocumentClicked,
} from "../../../components/documents-table";
import { OnInviteBuyerSolicitor } from "../events";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import LCCard from "../../../components/cards/lc-card";
import SelectSolicitor, { ActingForEnum } from "./actions/select-solicitor";
import OfferCard from "../../../components/offer-card";
import ParticipantList from "./participants-list";
import { useAuth } from "react-oidc-context";
import BottomNavToggle from "./bottom-nav";
import { Participant } from "../../../generated";

export interface BuyerWorkspaceViewProps {
  state: WorkspaceState;
  onOfferClicked: OnOfferClicked;
  onDocumentView: OnDocumentClicked;
  onInviteBuyerSolicitor: OnInviteBuyerSolicitor;
}

const BuyerWorkspaceView: FC<BuyerWorkspaceViewProps> = ({
  state,
  onDocumentView,
  onInviteBuyerSolicitor,
}) => {
  const auth = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const filterParticipantsByRole = (participant: Participant) => {
    return (
      (participant.role === "sellerAgent" ||
        participant.role === "buyerSolicitor") &&
      participant.phone
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column-reverse" },
        gap: 2,
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          flex: "7",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <LCCard
          title={`${state.workspace.property?.streetAddress1},
      ${state.workspace.property?.locality} ${state.workspace.property?.stateOrTerritory},
      ${state.workspace.property?.postCode}`}
          titleColor="#000"
          bgColor="#fff"
          txtColor="#6d6d6d"
        >
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Box
              sx={{
                borderRadius: "30px",
                marginBottom: 2,
                width: { lg: "700px", md: "100%" },
              }}
            >
              <img
                src={`https://landconnex-prod-public.ap-south-1.linodeobjects.com/${state.workspace.workspace?.id}.jpeg`}
                alt="property-image"
                style={{ borderRadius: "12px", width: "100%" }}
              />
            </Box>
          </Box>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Lot:{" "}
            <Typography variant="body1" component="span">
              {state.workspace.property?.lot}
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Plan:{" "}
            <Typography variant="body1" component="span">
              {state.workspace.property?.plan}
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            Title Reference:{" "}
            <Typography variant="body1" component="span">
              {state.workspace.property?.titleReference}
            </Typography>
          </Typography>
          <Box
            sx={{
              borderTop: "1px solid silver",
              marginTop: 3,
              marginBottom: 2,
            }}
          />

          <ParticipantList
            participants={state.participants}
            currentUserEmail={auth.user?.profile.email!}
            roles={["sellerAgent", "buyerSolicitor"]}
          />
        </LCCard>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          position: { md: "sticky", xs: "relative" },
          top: { md: 89, xs: 0 },
          flex: "3",
          width: { md: "auto", xs: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {state.offers.map((offer, index) => (
            <Box key={index}>
              <OfferCard
                offer={offer}
                workspace={state.workspace.workspace!}
                workflow={state.workflow!}
                boxShadow={true}
                bgColor="#fff"
              />
            </Box>
          ))}

          <SelectSolicitor
            actingFor={ActingForEnum.Buyer}
            onInviteSellerSolicitor={() => {}}
            offerId={state.offers?.[0].offer.id}
            onInviteBuyerSolicitor={onInviteBuyerSolicitor}
          />

          <LCCard
            title={"Documents"}
            txtColor="#273E47"
            bgColor="#014597"
            circleElementBefore="#4a84ad"
            circleElementAfter="#023676"
          >
            <DocumentsTable
              onDocumentViewClicked={onDocumentView}
              documents={state.documents}
            />
          </LCCard>
        </Box>
      </Box>
      {isMobile && (
        <Box sx={{ position: "relative" }}>
          <BottomNavToggle
            participants={state.participants}
            filterFunction={filterParticipantsByRole}
          />
        </Box>
      )}
    </Box>
  );
};

export default BuyerWorkspaceView;
