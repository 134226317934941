import React, { useState } from "react";
import {
  Box,
  Button,
  Slide,
  Typography,
  IconButton,
  Link,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Participant } from "../../../generated";
import { convertCamelCase } from "../../../helpers/helpers";

export interface BottomNavToggleProps {
  participants: Participant[];
  filterFunction: (participant: Participant) => string | boolean | undefined;
}

const BottomNavToggle: React.FC<BottomNavToggleProps> = ({
  participants,
  filterFunction,
}) => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const filteredParticipants = participants.filter(filterFunction);

  return (
    <Box sx={{ position: "relative", height: "0", overflow: "hidden" }}>
      <Button
        onClick={toggleDrawer}
        sx={{
          position: "fixed",
          bottom: open ? 75 : 0,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1100,
          backgroundColor: "#fff", // Background color
          boxShadow: open
            ? "0"
            : "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
          "&:hover": {
            backgroundColor: "#fff", // Change this to your desired hover color
          },
          borderRadius: "40px 40px 0 0",
        }}
      >
        {open ? (
          <CloseOutlinedIcon sx={{ color: "#8F1219" }} />
        ) : (
          <IconButton>
            <CallIcon color="success" />
          </IconButton>
        )}
      </Button>

      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            bgcolor: "background.paper",
            boxShadow: 3,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            padding: 2,
            zIndex: 1000,
            display: "flex",
            flexWrap: "wrap", // Allows wrapping of items
            justifyContent: "space-around",
          }}
        >
          {filteredParticipants.map((participant, index) => (
            <Link
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 1,
                textDecoration: "none",
              }}
              href={`tel:${participant.phone}`}
            >
              <IconButton>
                <CallIcon color="success" />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1">
                  {convertCamelCase(participant.role!)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {participant.firstName} {participant.lastName}
                </Typography>
              </Box>
            </Link>
          ))}
        </Box>
      </Slide>
    </Box>
  );
};

export default BottomNavToggle;
