import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ContractStepperStatus,
  contractStepperState,
  initialise,
  moveToStep,
} from "./contract-stepper-slice";

import React, { useEffect, useState } from "react";
import { getContract, getDocuments, getPdf } from "./thunks";

import { Box, Grid } from "@mui/material";
import LCCard from "../../components/cards/lc-card";
import {
  validateContractProperty,
  validateEncumbrances,
  validateSellerDetails,
  validateSellerSolicitor,
} from "../../utils/validationUtils";
import KonveiStepper from "../../components/konvei-stepper";

const steps = [
  "client",
  "agent",
  "solicitor",
  "property",
  "encumbrances",
  "special-conditions",
  "pool-safety",
  "safety-switches-and-alarms",
  "neighbourhood-disputes",
  "annexures",
  "review",
];
const ContractStepperPage2: React.FC = () => {
  const [validationErrors, setValidationErrors] = useState<
    { message: string; step: number }[]
  >([]);

  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    dispatch(getContract(workspaceId));
    dispatch(getDocuments(workspaceId));
    //  fetchDocuments();
  }, [workspaceId]);

  useEffect(() => {
    if (state.status == ContractStepperStatus.initial) {
      const pathSegments = location.pathname.split("/").filter(Boolean);
      const lastSegment = pathSegments[pathSegments.length - 1] || "/";
      dispatch(initialise(lastSegment));
    }
    if (state.status === ContractStepperStatus.annexureAdded) {
      dispatch(getDocuments(workspaceId));
    }
  }, [state.status, dispatch, workspaceId]);

  function onStepChanged(step: string): void {
    dispatch(moveToStep(step));

    navigate(step);

    if (step == "Review") {
      dispatch(getPdf(workspaceId));
    }
  }

  useEffect(() => {
    if (state.step == "Review") {
      const sellerDetails = validateSellerDetails(state.sellers, 0);
      const solicitorDetails = validateSellerSolicitor(
        state.sellerSolicitor,
        2
      );
      const contractProperty = validateContractProperty(
        state.workspace.property!,
        state.contract.id,
        
      );
      const encumbrances = validateEncumbrances(state.contract, 4);

      const toValidate = [
        ...sellerDetails,
        ...solicitorDetails,
        ...contractProperty,
        ...encumbrances,
      ];

      const allErrors = toValidate
        .filter((error) => error !== null)
        .map((error) => ({
          message: error,
          step: parseInt(error.match(/Part (\d+)/)?.[1] ?? "0"),
        }));
      setValidationErrors(allErrors);
      console.log(validationErrors);
      
    }
  }, [state.step]);

  return (
    <Grid item xs={12}>
      <LCCard
        title="Contract and Disclosures"
        txtColor="#000"
        titleColor="#000"
        bgColor="#fff"
        sxCard={{ overflow: "visible" }}
      >
        {state.status == ContractStepperStatus.initial ||
        state.status == ContractStepperStatus.loading ? (
          <></>
        ) : (
          <React.Fragment>
            <Box sx={{ position: "sticky", top: 68, zIndex: 1000 }}>
              <KonveiStepper
                steps={steps}
                onStepChanged={onStepChanged}
                activeStep={state.step}
                isCompressed={true}
              />
            </Box>
            <Outlet></Outlet>
          </React.Fragment>
        )}
      </LCCard>
    </Grid>
  );
};

export default ContractStepperPage2;
