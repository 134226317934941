import React from "react";
import { Box, Typography } from "@mui/material";
import { ActivityLog as ActivityLogType } from "../../../generated";
import { formatDateAndTime } from "../../../helpers/helpers";

type ActivityLogProps = {
  activityLog: ActivityLogType[];
};

const ActivityLog: React.FC<ActivityLogProps> = ({ activityLog }) => {
  const sortedActivityLog = [...activityLog].sort(
    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
  );

  return (
    <Box sx={{ height: 300, overflow: "auto" }}>
      {sortedActivityLog.map((activity, index) => (
        <Box
          key={index}
          sx={{
            padding: 1,
            backgroundColor: index === 0 ? "rgb(247, 247, 247)" : "#fff",
            color: "#000",
            borderRadius: "8px",
          }}
        >
          <Typography>{activity.summary}</Typography>
          <Typography
            sx={{
              fontSize: "12px",
              color: "gray",
            }}
          >
            {formatDateAndTime(activity.created)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ActivityLog;
