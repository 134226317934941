import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  Contract,
  Participant,
  Property,
  Workflow,
  WorkspaceSummary,
} from "../../generated";
import { addBuyer, deleteBuyer, getOffer, newOffer } from "./thunks";

export enum OfferStepperStatus {
  initial,
  loading,
  ready,
  deleteBuyerFulfilled,
  dirty,
}
interface OfferStepperState {
  status: OfferStepperStatus;
  workspace: WorkspaceSummary;
  contract: Contract;
  buyers: Array<Participant>;
  buyerSolicitor: Participant;
  property: Property;
  offer: Contract;
  workflow: Workflow;
}

const initialState: OfferStepperState = {
  status: OfferStepperStatus.initial,
  buyers: [],
  contract: {
    id: 0,
    status: "",
    hasEncumbrances: false,
    hasNeighbourhoodDisputes: false,
    hasPool: false,
    hasPoolCertificate: false,
    hasSafetySwitches: false,
    hasSellerSolicitor: false,
    hasSmokeAlarms: false,
    hasTenant: false,
  },
  workspace: {},
  buyerSolicitor: {},
  property: {},
  offer: {
    id: 0,
    status: "",
    hasEncumbrances: false,
    hasNeighbourhoodDisputes: false,
    hasPool: false,
    hasPoolCertificate: false,
    hasSafetySwitches: false,
    hasSellerSolicitor: false,
    hasSmokeAlarms: false,
    hasTenant: false,
  },
  workflow: {
    appointAgent: "not started",
    contractPreparation: "not started",
    state: "agent-appointment",
    subState: "",
    label: "",
    offers: [],
    // stage: "",
    // activities: []
  },
};
export const offerStepperSlice = createSlice({
  name: "offerStepper",
  initialState,
  reducers: {
    updateBuyer: (state, action: PayloadAction<Participant>) => {
      const index = state.buyers.findIndex((v) => v.id == action.payload.id);
      state.buyers[index] = action.payload;
      state.status = OfferStepperStatus.dirty;
    },
    updateOffer: (state, action: PayloadAction<Contract>) => {
      state.offer = action.payload;
      state.status = OfferStepperStatus.dirty;
    },
    updateBuyerSolicitor: (state, action: PayloadAction<Participant>) => {
      state.buyerSolicitor = action.payload;
      state.status = OfferStepperStatus.dirty;
    },
    acknowledgeBuyerDeleted: (state) => {
      state.status == OfferStepperStatus.ready;
      state.status = OfferStepperStatus.dirty;
    },
  },
  extraReducers: (builder) => {
    newOfferReducer(builder);
    builder.addCase(getOffer.pending, (state) => {
      state.status = OfferStepperStatus.loading;
    });
    builder.addCase(getOffer.fulfilled, (state, action) => {
      state.contract = action.payload.contract;
      state.offer = action.payload.offer;
      state.property = action.payload.property;
      state.buyers = action.payload.buyers;
      state.buyerSolicitor = action.payload.buyersSolicitor;
      state.status = OfferStepperStatus.ready;
      state.workspace = action.payload.workspace;
    });
    builder.addCase(addBuyer.fulfilled, (state, action) => {
      const newBuyer: Participant = action.payload;
      state.buyers.push(newBuyer);
    });
    deleteBuyerReducer(builder);
  },
});

function deleteBuyerReducer(
  builder: ActionReducerMapBuilder<OfferStepperState>
) {
  builder.addCase(deleteBuyer.fulfilled, (state, action) => {
    state.status == OfferStepperStatus.deleteBuyerFulfilled;
    state.buyers.splice(state.buyers.findIndex((p) => p.id == action.payload));
  });
}
function newOfferReducer(builder: ActionReducerMapBuilder<OfferStepperState>) {
  builder.addCase(newOffer.pending, (state) => {
    state.buyers = [];
    state.contract = {
      id: 0,
      status: "",
      hasEncumbrances: false,
      hasNeighbourhoodDisputes: false,
      hasPool: false,
      hasPoolCertificate: false,
      hasSafetySwitches: false,
      hasSellerSolicitor: false,
      hasSmokeAlarms: false,
      hasTenant: false,
    };
    state.buyerSolicitor = {};
    state.offer = {
      status: "",
      id: 0,
      hasEncumbrances: false,
      hasNeighbourhoodDisputes: false,
      hasPool: false,
      hasPoolCertificate: false,
      hasSafetySwitches: false,
      hasSellerSolicitor: false,
      hasSmokeAlarms: false,
      hasTenant: false,
    };
  });

  builder.addCase(newOffer.fulfilled, (state, action) => {
    state.offer = {
      status: "",
      id: 0,
      hasEncumbrances: false,
      hasNeighbourhoodDisputes: false,
      hasPool: false,
      hasPoolCertificate: false,
      hasSafetySwitches: false,
      hasSellerSolicitor: false,
      hasSmokeAlarms: false,
      hasTenant: false,
    };
    const buyer: Participant = {
      id: action.payload.buyerId,
      role: "buyer",
      firstName: "",
      lastName: "",
    };
    state.buyers.push(buyer);
    state.buyerSolicitor = {
      id: action.payload.buyerSolicitorId,
      role: "buyerSolicitor",
    };
  });
}

export const {
  updateBuyer,
  updateBuyerSolicitor,
  updateOffer,
  acknowledgeBuyerDeleted,
} = offerStepperSlice.actions;
export default offerStepperSlice.reducer;
export const offerStepperState = (state: RootState) => state.offerStepper;
