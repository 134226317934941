import { FC, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Typography, Alert, FormControl, TextField } from "@mui/material";
import LCCard from "../../../../components/cards/lc-card";
import { OnInviteBuyerSolicitor, OnInviteSolicitor } from "../../events";
import LCButton from "../../../../components/button";

export enum ActingForEnum {
  Buyer,
  Seller,
}

export interface SelectSolicitorArgs {
  onInviteSellerSolicitor: OnInviteSolicitor;
  onInviteBuyerSolicitor: OnInviteBuyerSolicitor;
  actingFor: ActingForEnum;
  offerId?: number;
}

const SelectSolicitor: FC<SelectSolicitorArgs> = ({
  onInviteSellerSolicitor,
  onInviteBuyerSolicitor,
  actingFor,
  offerId,
}) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [invitedEmail, setInvitedEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleInvite = () => {
    if (validateEmail(email)) {
      if (actingFor == ActingForEnum.Seller) {
        onInviteSellerSolicitor(email);
      } else if (actingFor == ActingForEnum.Buyer) {
        onInviteBuyerSolicitor(offerId!, email);
      }
      setSuccess(true);
      setInvitedEmail(email);
      setEmail("");
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <Grid container gap={3} justifyContent="center">
      {/* <Box maxWidth="700px"> */}
      <LCCard
        title="Invite Solicitor"
        txtColor="#000"
        titleColor="#000"
        bgColor="#fff"
      >
        <Box sx={{ padding: "15px" }}>
          <Typography sx={{ textAlign: "justify", marginBottom: 3 }}>
            Invite a solicitor to the workspace to prepare the contract
            disclosures. The solicitors below are recommended by your agent. You
            can also choose to invite a solicitor of your own choice by
            providing their email address. Until you have invited a solicitor to
            prepare your contract, the sale of your property cannot proceed. You
            can continue to use the solicitor to represent you during the
            property conveyance, or you can elect to use a different solicitor
            after the contract disclosures are completed.
          </Typography>
          {success && (
            <Alert severity="success" sx={{ marginBottom: 2 }}>
              Solicitor invitation was successfully sent to {invitedEmail}.
            </Alert>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: 5,
              justifyContent: "space-between",
            }}
          >
            <FormControl sx={{ minWidth: 300 }}>
              {/* <InputLabel id="solicitor-email-label">
                Solicitor Email
              </InputLabel> */}
              {/* <Select
                labelId="solicitor-email-label"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (success) setSuccess(false);
                  if (emailError) setEmailError("");
                }}
                label="Solicitor Email"
                fullWidth
                variant="filled"
              >
                <MenuItem value="" disabled>
                  Select a Solicitor Email
                </MenuItem>
                <MenuItem value="erick-solicitor@landconnex.com.au">
                  info@therealestatelawyer.com.au
                </MenuItem>
              </Select> */}

              <TextField
                label="Solicitor Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (success) setSuccess(false);
                  if (emailError) setEmailError("");
                }}
                fullWidth
                variant="filled"
                error={Boolean(emailError)}
                helperText={emailError}
              />
            </FormControl>
            <Box sx={{ width: "200px" }}>
              <LCButton label="Invite" color="#8F1219" onClick={handleInvite} />
            </Box>
          </Box>
        </Box>
      </LCCard>
      {/* </Box> */}
    </Grid>
  );
};

export default SelectSolicitor;
