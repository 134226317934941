import TextField from "@mui/material/TextField";
import { FC } from "react";
import { NumericFormat } from "react-number-format";

export interface CurrencyFieldProps {
  amount?: number;
  label: string;
  required?: boolean;
  onChange?: (amount: number | undefined) => void;
}
const CurrencyField: FC<CurrencyFieldProps> = ({
  amount,
  required,
  label,
  onChange,
}) => {
  let val;
  if (amount) {
    val = amount / 100;
    val = val.toFixed(2);
  }
  return (
    <NumericFormat
      value={val}
      customInput={TextField}
      prefix="$ "
      variant="filled"
      fixedDecimalScale
      label={label}
      required={required}
      decimalScale={2}
      onValueChange={(values) => {
        values.floatValue
        let t = values.floatValue! * 100;
        onChange!(Math.round(t)); 
      }}
      
      size="small"
      thousandSeparator
    />
  );
};

export default CurrencyField;
