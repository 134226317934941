import { FC } from "react";
import Container from "@mui/material/Container";
import Page from "../../components/page";
import CreateWorkspace from "./create-workspace";
import { Grid } from "@mui/material";

const CreateWorkspacePage: FC = () => {
  return (
    <Page>
      <Container
        style={{
          marginTop: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container>
          <CreateWorkspace />
        </Grid>
      </Container>
    </Page>
  );
};

export default CreateWorkspacePage;
