import React, { ReactNode } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import { redirectUri } from "./constants";

interface RequireAuthProps {
  children?: ReactNode;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  if (
    !hasAuthParams() &&
    !auth.isAuthenticated &&
    !auth.activeNavigator &&
    !auth.isLoading
  ) {
    // If the user is not authenticated, redirect them to the login page.
    // You can also pass along the current location they were trying to access
    // if you want to redirect them back after successful login.
    console.log(location);

    console.log(location);

    auth.signinRedirect({
      redirect_uri: redirectUri + location.pathname,
    });
    return <></>;
  }

  // If the user is authenticated, render the children components
  // or render the <Outlet /> if no children are specified.
  // This allows the component to be used for nested routes.
  return <>{children}</>;
};

export default RequireAuth;
