import Avatar from "@mui/material/Avatar";
import { FC, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { Box, Divider, Typography } from "@mui/material";

export interface AccountAvatarProps {
  email: string;
}

const AccountAvatar: FC<AccountAvatarProps> = ({ email }) => {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  // const [checked, setChecked] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenSettings = () => {
    navigate("/settings");
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        sx={{
          padding: {
            sm: "8px",
            xs: "5px 8px",
          },
        }}
      >
        <Avatar
          sx={{
            width: { sm: 34, xs: 29 },
            height: { sm: 34, xs: 29 },
            marginRight: { sm: 2, xs: 1 },
            bgcolor: "#8F1219",
            fontSize: 11,
          }}
        >
          {`${auth.user?.profile.given_name?.charAt(
            0
          )}${auth.user?.profile.family_name?.charAt(0)}`}
        </Avatar>
        <SettingsOutlinedIcon
          fontSize={"medium"}
          sx={{
            width: { sm: 30, xs: 25 },
            height: { sm: 30, xs: 25 },
            color: "#2A629A",
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            width: "300px",
            maxWidth: "100%",
            borderRadius: 3,
          },
        }}
      >
        <Box
          sx={{
            padding: "25px 15px",
          }}
        >
          <Typography variant="h6" align="center">
            Welcome Back!<Typography>{email}</Typography>
          </Typography>
          <Divider sx={{ margin: "30px 10px" }} />
          {/* <Box
            sx={{
              background: "rgb(238, 242, 246)",
              borderRadius: 2,
              padding: "7px",
              marginBottom: 2,
            }}
          >
            <FormGroup>
              <FormControlLabel
                sx={{
                  textAlign: "left",
                  justifyContent: "space-between",
                  ml: 1,
                  width: "100%",
                  "& .MuiFormControlLabel-label": {
                    flexGrow: 1,
                  },
                }}
                control={<Switch checked={checked} onChange={handleChange} />}
                label="Notification"
                labelPlacement="start"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                sx={{
                  textAlign: "left",
                  justifyContent: "space-between",
                  ml: 1,
                  width: "100%",
                  "& .MuiFormControlLabel-label": {
                    flexGrow: 1,
                  },
                }}
                control={<Switch checked={checked} onChange={handleChange} />}
                label="Push Email Notification"
                labelPlacement="start"
              />
            </FormGroup>
          </Box> */}
          <MenuItem onClick={handleOpenSettings}>
            <SettingsOutlinedIcon sx={{ marginRight: 2 }} />
            Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              auth.signoutRedirect();
            }}
          >
            <ExitToAppOutlinedIcon sx={{ marginRight: 2 }} /> Signout
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
};

export default AccountAvatar;
