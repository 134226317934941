import { FC, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import type { PDFDocumentProxy } from "pdfjs-dist";

import "./pdf-viewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();
const options = {
  standardFontDataUrl: "/standard_fonts/",
};
type PDFViewerProps = {
  url: string;
};

// const resizeObserverOptions = {};
const maxWidth = 800;

const PDFViewer: FC<PDFViewerProps> = ({ url }) => {
  const [numPages, setNumPages] = useState(0);
 // const [containerWidth, setContainerWidth] = useState<number>();
 // const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);

  // const onResize = useCallback<ResizeObserverCallback>((entries) => {
  //   const [entry] = entries;

  //   if (entry) {
  //    // setContainerWidth(entry.contentRect.width);
  //   }
  // }, []);
  // useResizeObserver(containerRef, resizeObserverOptions, onResize);
  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  return (
    <div className="Example__container">
      <div className="Example__container__document">
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={
              maxWidth
            }
          />
        ))}
      </Document>
      </div>
    </div>
    // <Document
    //   file={url}

    //   onLoadSuccess={onDocumentLoadSuccess}
    //   options={options}
    // >
    //   {Array.apply(null, Array(numPages))
    //     .map((_, i) => i + 1)
    //     .map((page) => (
    //       <Page
    //         pageNumber={page}
    //         key={`page_${inde}`}
    //         renderAnnotationLayer={false}
    //         renderTextLayer={false}
    //         width={containerWidth ? Math.min(containerWidth, maxWidth): maxWidth}

    //       />
    //     ))}
    // </Document>
  );
};

export default PDFViewer;
