import { createAsyncThunk } from "@reduxjs/toolkit";
import { workspacesApi } from "../../api";

export const accept = createAsyncThunk<string, string>(
  "acceptInvitation/accept",
  async (invitationToken) => {
    await workspacesApi.acceptInvitation(invitationToken);
    return invitationToken;
  }
);
