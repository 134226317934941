import React from "react";
import { Grid } from "@mui/material";
import WorkspaceSummary from "./workspace-sumary";
import { useNavigate } from "react-router-dom";

interface WorkspaceContentsProps {
  workspaces: any[];
  isCardView: boolean;
  maxItems?: number;
  workflowStates: any[];
}

const WorkspaceContents: React.FC<WorkspaceContentsProps> = ({
  workspaces,
  isCardView,
  maxItems,
  workflowStates,
}) => {
  const navigate = useNavigate();
  const displayWorkspaces = maxItems
    ? workspaces.slice(0, maxItems)
    : workspaces;

  const handleWorkspaceClicked = (workspaceId: number) => {
    navigate(`/workspaces/${workspaceId}`);
  };

  return (
    <Grid container sx={{ padding: 0 }}>
      {displayWorkspaces.map((w, i) => (
        <Grid
          key={`wsg-${i}`}
          item
          xl={isCardView ? 3 : 6}
          lg={isCardView ? 4 : 6}
          md={isCardView ? 6 : 6}
          xs={12}
          sx={{ padding: 1 }}
        >
          <WorkspaceSummary
            onClick={() => handleWorkspaceClicked(w.id)}
            workspaceSummary={w}
            key={`ws-${w.id}`}
            workflow={workflowStates.find((s) => s.workspaceId === w.id)}
            isCardView={isCardView}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default WorkspaceContents;
