import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { FC, useCallback, useEffect } from "react";
import {
  AgentAppointment,
  AgentAppointmentAppointmentTermTypeEnum,
} from "../../../generated";
import { OnAgentAppointmentChanged } from "../../../components/events";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { Box, Checkbox, debounce, FormGroup, TextField } from "@mui/material";
import useDeepCompareEffect from "use-deep-compare-effect";
import FormInputText from "../../../components/form-input-text";
import FormInputDatePicker from "../../../components/form-input-date-picker";
import FormWrapper from "./form-wrapper";
import LCButton from "../../../components/button";
import CurrencyField from "../../../components/currency-field";

export interface AppointmentProps {
  agent: AgentAppointment;
  onAgentChanged: OnAgentAppointmentChanged;
}
const Appointment: FC<AppointmentProps> = ({ agent, onAgentChanged }) => {
  const schema = yup.object<AgentAppointment>({
    appointmentIsOtherDescription: yup.string().when(["appointmentIsOther"], {
      is: (val: boolean) => val,
      then: () => yup.string().required(),
    }),
    priceList: yup.number().required(),
  });

  const { handleSubmit, control, formState, reset, trigger } =
    useForm<AgentAppointment>({
      defaultValues: agent,
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    reset(agent);
    trigger();
  }, [agent]);

  const onSubmit: SubmitHandler<AgentAppointment> = (data) => {
    onAgentChanged(data);
  };

  const watchedData = useWatch({
    control: control,
    defaultValue: agent,
  });
  const debouncedSave = useCallback(
    debounce(() => {
      console.log("Saving");
      handleSubmit(onSubmit)();
    }, 1000),
    []
  );

  useDeepCompareEffect(() => {
    console.log("Triggered");
    if (formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedAgent = { ...agent };

    // Update the relevant boolean field based on the checkbox name
    switch (name) {
      case "Sale":
        updatedAgent.appointmentIsSale = checked;
        break;
      case "Purchase":
        updatedAgent.appointmentIsPurchase = checked;
        break;
      case "Letting":
        updatedAgent.appointmentIsLetting = checked;
        break;
      case "Leasing":
        updatedAgent.appointmentIsLeasing = checked;
        break;
      case "Auction":
        updatedAgent.appointmentIsAuction = checked;
        break;
      case "Other":
        updatedAgent.appointmentIsOther = checked;
        break;
      default:
        break;
    }

    onAgentChanged(updatedAgent);
  };

  return (
    <FormWrapper title="Part 4 - Appointment of property agent">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          className="section-1"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 4,
            borderBottom: "1px solid silver",
            paddingBottom: 5,
          }}
        >
          <Typography variant="h6" component="span" className="roboto-bold">
            Section 1
          </Typography>
          <Box>
            <Typography variant="h6">Performance of Service</Typography>
            <Typography variant="body2">
              Annexures detailing the performance of service may be attached if
              required.
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" variant="filled" fullWidth>
                <Typography component="legend">Service</Typography>
                <FormGroup sx={{ display: "inline-block" }}>
                  {[
                    { name: "Sale", field: "appointmentIsSale" },
                    { name: "Purchase", field: "appointmentIsPurchase" },
                    { name: "Letting", field: "appointmentIsLetting" },
                    { name: "Leasing", field: "appointmentIsLeasing" },
                    { name: "Auction", field: "appointmentIsAuction" },
                    { name: "Other", field: "appointmentIsOther" },
                  ].map(({ name, field }) => (
                    <FormControlLabel
                      key={field}
                      control={
                        <Checkbox
                          checked={!!agent[field as keyof AgentAppointment]} // Access the specific boolean field
                          onChange={handleCheckboxChange}
                          name={name} // Name matches the switch cases
                        />
                      }
                      label={name}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>

            {agent.appointmentIsOther ? (
              <Grid item xs={6}>
                <FormInputText
                  control={control}
                  name="appointmentIsOtherDescription"
                  label="Description of Other"
                  required
                />
              </Grid>
            ) : null}
          </Grid>
        </Box>

        <Box
          className="section-2"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 4,
            borderBottom: "1px solid silver",
            paddingBottom: 5,
          }}
        >
          <Typography variant="h6" component="span" className="roboto-bold">
            Section 2
          </Typography>
          <Typography variant="h6">Term of Appointment</Typography>
          <Typography variant="body2">
            Sole and exclusive appointments: for sales of one or two residential
            properties, the term is negotiable and agent can be appointed or
            reappointed up to a maximum of 90 days per term. There are no
            limitations on the length of an appointment for anything other than
            a residential property sale.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={
                  agent.appointmentTermType
                    ? agent.appointmentTermType
                    : AgentAppointmentAppointmentTermTypeEnum.Single
                }
                onChange={(value) => {
                  const u = { ...agent };
                  u.appointmentTermType = value.currentTarget
                    .value as AgentAppointmentAppointmentTermTypeEnum;
                  onAgentChanged(u);
                }}
                row
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={AgentAppointmentAppointmentTermTypeEnum.Single}
                  control={<Radio />}
                  label="Single Appointment"
                />

                <FormControlLabel
                  value={AgentAppointmentAppointmentTermTypeEnum.Continuing}
                  control={<Radio />}
                  label="Continuing Appointment"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <FormInputDatePicker
                control={control}
                label="Start"
                name="appointmentTermStart"
                required
              />
            </Grid>
            {agent.appointmentTermType ==
            AgentAppointmentAppointmentTermTypeEnum.Single ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <FormInputDatePicker
                  control={control}
                  label="End"
                  name="appointmentTermEnd"
                  required={
                    agent.appointmentTermType ==
                    AgentAppointmentAppointmentTermTypeEnum.Single
                  }
                />
                <Box sx={{ width: "100px", marginTop: 1 }}>
                  <LCButton
                    label="No end date"
                    color="#000"
                    size="small"
                    onClick={() => {
                      const updated = { ...agent };
                      updated.appointmentTermEnd = undefined;
                      onAgentChanged(updated);
                    }}
                  />
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </Box>

        <Box
          className="section-3"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 4,
            borderBottom: "1px solid silver",
            paddingBottom: 5,
          }}
        >
          <Typography variant="h6" component="span" className="roboto-bold">
            Section 3
          </Typography>
          <Typography variant="h6">Price</Typography>
          <Typography variant="body2">
            State the price for which the property, land or business is to be
            sold or let. <br />
            <Typography component="span" sx={{ color: "#c20029" }}>
              Note:{" "}
            </Typography>{" "}
            Bait advertising is an offence under the Australian Consumer Law.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <CurrencyField
                label="Price"
                amount={agent.priceList}
                required
                onChange={(amount) => {
                  const updatedAgent = { ...agent };
                  updatedAgent.priceList = amount;
                  onAgentChanged(updatedAgent);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                label="Marketing Range"
                value={agent.priceMarketingRange}
                variant="filled"
                onChange={(e: any) => {
                  const updatedAgent = { ...agent };
                  updatedAgent.priceMarketingRange = e.target.value;
                  onAgentChanged(updatedAgent);
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          className="section-4"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 4,
          }}
        >
          <Typography variant="h6" component="span" className="roboto-bold">
            Section 4
          </Typography>
          <Typography variant="h6">Instructions / Conditions</Typography>
          <Typography variant="body2">
            The client may list any condition, limitation or restriction on the
            performance of the service. <br />
            <Typography component="span" sx={{ color: "#c20029" }}>
              Note:{" "}
            </Typography>
            Annexures detailing instructions/conditions may be attached if
            required.
          </Typography>
          <Grid item xs={12}>
            <FormInputText
              control={control}
              label="Instructions"
              name="instructions"
              multiline
              minRows={5}
            />
          </Grid>
        </Box>
      </Box>
    </FormWrapper>
  );
};

export default Appointment;
