import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  cancelInvitation,
  getTeam,
  getTeamInvitations,
  getTeamMembers,
  inviteUserToTeam,
  removeUserFromTeam,
  updateUser,
} from "./thunks";
import { RootState } from "../../app/store";
import { Team, TeamInvitation, TeamParticipant } from "../../generated";

export enum ManageUsersStatus {
  initial,
  ready,
  updating,
}

export interface ManageUsersState {
  status: ManageUsersStatus;
  members: Array<TeamParticipant>;
  teamInvitations: Array<TeamInvitation>;
  team: Team;
}

const initialState: ManageUsersState = {
  status: ManageUsersStatus.initial,
  members: [],
  teamInvitations: [],
  team: { name: "" },
};

export const manageUsersSlice = createSlice({
  name: "manageUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getMembersReducers(builder);
    cancelInvitationReducers(builder);
    getTeamInvitationsReducers(builder);
    getTeamReducers(builder);
    inviteUserToTeamReducers(builder);
    updateUserReducers(builder);
    removeUserFromTeamReducers(builder);
  },
});

function getMembersReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>
) {
  builder.addCase(getTeamMembers.fulfilled, (state, action) => {
    state.status = ManageUsersStatus.ready;
    state.members = action.payload.users!;
  });

  builder.addCase(getTeamMembers.pending, (state) => {
    state.status = ManageUsersStatus.updating;
  });
}

function getTeamReducers(builder: ActionReducerMapBuilder<ManageUsersState>) {
  builder.addCase(getTeam.fulfilled, (state, action) => {
    state.status = ManageUsersStatus.ready;
    state.team = action.payload;
  });

  builder.addCase(getTeam.pending, (state) => {
    state.status = ManageUsersStatus.updating;
  });
}

function removeUserFromTeamReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>
) {
  builder.addCase(removeUserFromTeam.fulfilled, (state) => {
    state.status = ManageUsersStatus.initial;
  });

  builder.addCase(removeUserFromTeam.pending, (state) => {
    state.status = ManageUsersStatus.updating;
  });
}

function updateUserReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>
) {
  builder.addCase(updateUser.fulfilled, (state) => {
    state.status = ManageUsersStatus.initial;
  });

  builder.addCase(updateUser.pending, (state) => {
    state.status = ManageUsersStatus.updating;
  });
}

function cancelInvitationReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>
) {
  builder.addCase(cancelInvitation.fulfilled, (state) => {
    state.status = ManageUsersStatus.initial;
  });

  builder.addCase(cancelInvitation.pending, (state) => {
    state.status = ManageUsersStatus.updating;
  });
}

function inviteUserToTeamReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>
) {
  builder.addCase(inviteUserToTeam.fulfilled, (state) => {
    state.status = ManageUsersStatus.initial;
  });

  builder.addCase(inviteUserToTeam.pending, (state) => {
    state.status = ManageUsersStatus.updating;
  });
}
function getTeamInvitationsReducers(
  builder: ActionReducerMapBuilder<ManageUsersState>
) {
  builder.addCase(getTeamInvitations.fulfilled, (state, action) => {
    state.teamInvitations = action.payload;
    state.status = ManageUsersStatus.ready;
  });

  builder.addCase(getTeamInvitations.pending, (state) => {
    state.status = ManageUsersStatus.updating;
  });
}
export const {} = manageUsersSlice.actions;
export default manageUsersSlice.reducer;
export const manageUsersState = (state: RootState) => state.manageUsers;
