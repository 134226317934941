import React, {  Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { Contract } from "../../../generated/api";
import { Box, useTheme } from "@mui/material";
import FormInputText from "../../../components/form-input-text";
import FormInputDatePicker from "../../../components/form-input-date-picker";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import FormInputNumeric from "../../../components/form-input-numeric";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { saveContract } from "../thunks";
import AutoSave from "../../../components/auto-save";
const Encumbrances: React.FC = () => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const formMethods = useForm<any>({
    defaultValues: state.contract,
  });

  const { control, handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<Contract> = (data) => {
    dispatch(saveContract(data));
  };

  // function onChange(event: ChangeEvent<HTMLInputElement>): void {
  //   const newContract = { ...state.contract };
  //   switch (event.currentTarget.name) {
  //     case "hasEncumbrances":
  //       newContract.hasEncumbrances = event.currentTarget.value == "true";
  //       break;
  //     case "hasTenant":
  //       newContract.hasTenant = event.currentTarget.value == "true";
  //       break;

  //     default:
  //       break;
  //   }
  //   dispatch(saveContract(newContract));
  // }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper title="Part 5 - Encumbrances">
          <Box
            sx={{
              maxWidth: "1024px",
              margin: "15px auto",
              padding: "30px",
              background: "#faf7f7",
              borderRadius: "30px",
              boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Title Encumbrances
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Typography variant="body1">
                  Is the Property sold subject to any Encumbrances?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="hasEncumbrances"
                  control={control}
                  render={() => (
                    <RadioGroup
                      defaultValue={
                        state.contract.hasEncumbrances ? "true" : "false"
                      }
                      onChange={({ target }) => {
                        const contract = { ...state.contract };
                        contract.hasEncumbrances = target.value == "true";
                        onSubmit(contract);
                      }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  )}
                ></Controller>
              </Grid>
              {state.contract.hasEncumbrances ? (
                <Grid item xs={12} sm={12}>
                  <FormInputText
                    name="encumbrances"
                    label="Encumbrances"
                    control={control}
                    required
                    multiline
                    minRows={6}
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Tenancies
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Typography variant="body1">
                  Is the Property tenanted?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  control={control}
                  name="hasTenant"
                  render={() => (
                    <RadioGroup
                    defaultValue={
                      state.contract.hasTenant ? "true" : "false"
                    }
                    onChange={({ target }) => {
                      const contract = { ...state.contract };
                      contract.hasTenant = target.value == "true";
                      onSubmit(contract);
                    }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
              {state.contract.hasTenant ? (
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <FormInputText
                      control={control}
                      name="tenancyTenantName"
                      label="Tenants Name"
                      required={state.contract.hasTenant}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormInputText
                      control={control}
                      name="tenancyTermAndOptions"
                      label="Terms & Options"
                      multiline
                      minRows={4}
                      required={state.contract.hasTenant}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormInputDatePicker
                      control={control}
                      name="tenancyTermStart"
                      label="Tenancy Term Start"
                      required={state.contract.hasTenant}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormInputDatePicker
                      control={control}
                      name="tenancyTermEnd"
                      label="Tenancy Term End"
                      required={state.contract.hasTenant}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormInputNumeric
                      control={control}
                      name="tenancyRent"
                      label="Rent"
                      required={state.contract.hasTenant}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormInputNumeric
                      control={control}
                      name="tenancyBond"
                      label="Bond"
                      required={state.contract.hasTenant}
                    />
                  </Grid>
                </Fragment>
              ) : null}
            </Grid>
          </Box>
        </FormWrapper>
        <AutoSave onSubmit={onSubmit} defaultValues={state.contract}></AutoSave>
      </form>
    </FormProvider>
  );
};

export default Encumbrances;
