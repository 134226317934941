import { createAsyncThunk } from "@reduxjs/toolkit";
import { contractsApi, offersApi, participantsApi } from "../../api";
import { OfferDetail, Participant } from "../../generated";

export const initialise = createAsyncThunk(
  "contract/initialise",
  async (args: number) => {
    const response = await contractsApi.getContract(args);
    return response.data;
  }
);

// export const newOffer = createAsyncThunk("contract/newOffer", async (args: number) => {
//   const offer : Offer = {}
//   const response = await offersApi.postOffer(args, offer );
//   return response.data;
// });

export interface GetOffersResponse {
  offers: Array<OfferDetail>;
  contractParticipants: Array<Participant>;
}

export const getOffers = createAsyncThunk(
  "contract/getOffers",
  async (contractId: number) => {
    const getOffersResponse = await offersApi.getOffers(contractId);
    const getContractResponse = await participantsApi.getParticipants(
      contractId
    );
    const response: GetOffersResponse = {
      offers: getOffersResponse.data.items!,
      contractParticipants: getContractResponse.data.items!,
    };
    return response;
  }
);
// export const create = createAsyncThunk("contracts/create", async () => {
//   const arg: NewMatter = {matterReference: "sdfsdf" +  Math.round(Date.now() / 1000)};
//   const response = await mattersApi.postMatters(arg);
//   return response.data;
// });
