import { createAsyncThunk } from "@reduxjs/toolkit";
import { authority, client_id, konvei_api } from "../../constants";
import { User } from "oidc-client-ts";
import axios from "axios";

export interface GetDocumentArgs{
    workspaceId: number;
    documentName: string;
  }
  export const getDocument = createAsyncThunk<string, GetDocumentArgs>(
    "workspace/getDocument",
    async ({workspaceId, documentName}) => {
      const oidcStorage = sessionStorage.getItem(
        `oidc.user:${authority}:${client_id}`
      );
      const user = User.fromStorageString(oidcStorage!);
      
      const response = await axios.get(
        `${konvei_api}/workspaces/${workspaceId}/documents/${documentName}`,
        {
          headers: { Authorization: `Bearer ${user.access_token}` },
          responseType: "blob",
        }
      );
  
      const url = window.URL.createObjectURL(response.data!);
  
      return url;
    }
  );