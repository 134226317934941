import { FC, useEffect, useState } from "react";
import { DocumentSummary } from "../generated";
import { Box } from "@mui/material";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";

export type OnDocumentClicked = (documentName: string) => void;
export interface DocumentsTableProps {
  documents: Array<DocumentSummary>;
  onDocumentViewClicked?: OnDocumentClicked;
  onDocumentDownloadClicked?: OnDocumentClicked;
}
interface KeyedDocumentSummary extends DocumentSummary {
  id: number;
}

const DocumentsTable: FC<DocumentsTableProps> = ({
  documents,
  onDocumentViewClicked,
  // onDocumentDownloadClicked,
}) => {
  const [items, setItems] = useState<KeyedDocumentSummary[]>([]);

  useEffect(() => {
    const keyedDocuments = documents
      .filter((item) => item.documentType !== "Annexure")
      .map((item, index) => ({
        ...item,
        id: index,
      }));
    setItems(keyedDocuments);
  }, [documents]);

  return (
    <div id="documentList">
      <Box>
        {items.map((item, index) => (
          <Box
            key={index}
            onClick={() => onDocumentViewClicked?.(item.name!)}
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#fff",
              margin: "15px 0",
              gap: 2,
              cursor: "pointer",
              padding: 1,
              "&:hover": { backgroundColor: "#4a84ad" },
            }}
          >
            <DownloadForOfflineOutlinedIcon fontSize="large" />
            <Box>
              <Box>Filename: {item.name}</Box>
              <Box>Size: {Math.round(item.size! / 1000)}kb</Box>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default DocumentsTable;
