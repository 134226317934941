import { Grid, Box } from "@mui/material";
import React, { Fragment } from "react";
import { Participant } from "../../../generated";
import { OnParticipantChanged } from "../../../components/events";
import ClientDetail from "./client-detail";
import LCButton from "../../../components/button";
import FormWrapper from "./form-wrapper";

export type OnParticipantAdded = () => void;

export interface ClientDetailsProps {
  clients: Array<Participant>;
  onChanged: OnParticipantChanged;
  onRemoved: OnParticipantChanged;
  onAdded: OnParticipantAdded;
}

const ClientDetails: React.FC<ClientDetailsProps> = ({
  clients,
  onRemoved,
  onChanged,
  onAdded,
}) => {
  const notes = [
    {
      title: "Note:",
      items: [
        "The client is the person or entity appointing the agent to provide the services. This may be the owner (or authorised representative of the owner) of the land, property or business that is to be sold or may be a prospective buyer seeking to purchase land or a property.",
        "Fields marked with * are required.",
        "Annexures detailing additional clients may be attached if required.",
      ],
    },
  ];
  return (
    <FormWrapper title="Part 1 - Client Details" notes={notes}>
      <Fragment>
        {clients.map((client, index) => (
          <ClientDetail
            key={client.id}
            client={client}
            index={index}
            onChanged={onChanged}
            onDelete={onRemoved}
          />
        ))}

        <Grid container spacing={2} justifyContent={"center"}>
          <Box sx={{ width: "220px", marginTop: 3 }}>
            <LCButton
              label="add another client"
              color="#000"
              onClick={() => onAdded()}
            />
          </Box>
        </Grid>
      </Fragment>
    </FormWrapper>
  );
};

export default ClientDetails;
