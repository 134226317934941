import "./App.css";
import { Provider } from "react-redux";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { store } from "./app/store";
import OfferStepperPage from "./features/offer-stepper/offer-stepper-page";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-au";
import AgentAppointmentPage from "./features/agent-appointment/agent-appointment-page";
import CreateWorkspacePage from "./features/create-workspace/create-workspace-page";
import WorkspacePage from "./features/workspace/workspace-page";
import WorkspacesPage from "./features/workspaces/workspaces-page";
import AcceptInvitation from "./features/accept-invitation/accept-invitation";
import ViewDocument from "./features/view-document/view-document";
import RequireAuth from "./require-auth";
import SettingsPage from "./features/settings/settings-page";
import MyDetails from "./features/settings/components/my-details";
import Licencee from "./features/settings/components/licencee-details";
import LandingPage from "./features/landing/landing-page";
import ManageUsers from "./features/manage-users";
import ContractStepperPage2 from "./features/contract-stepper/contract-stepper-page";
import SellerDetails from "./features/contract-stepper/components/seller-details";
import SellerSolicitor from "./features/contract-stepper/components/seller-solicitor";
import SellerAgent from "./features/contract-stepper/components/seller-agent";
import PropertyStep from "./features/contract-stepper/components/property";
import Encumbrances from "./features/contract-stepper/components/encumbrances";
import PoolSafety from "./features/contract-stepper/components/pool-safety";
import SpecialConditions from "./features/contract-stepper/components/special-conditions";
import NeighbourhoodDisputes from "./features/contract-stepper/components/neighbourhood-disputes";
import Annexures from "./features/contract-stepper/components/annexures";
import SafetyAlarmsAndSwitches from "./features/contract-stepper/components/safety-alarms-and-switches";
import Review from "./features/contract-stepper/components/review";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <LandingPage />
      </RequireAuth>
    ),
    id: "landingPage",
  },
  {
    path: "/workspaces",
    element: (
      <RequireAuth>
        <WorkspacesPage />
      </RequireAuth>
    ),

    id: "workspaces",
  },
  {
    path: "/create-workspace",
    element: (
      <RequireAuth>
        <CreateWorkspacePage />
      </RequireAuth>
    ),
    id: "create-workspace",
  },
  {
    path: "/workspaces/:workspaceId",
    element: (
      <RequireAuth>
        <WorkspacePage />
      </RequireAuth>
    ),
    id: "view-workspace",
    children: [
      {
        path: "contract",
        element: (
          <RequireAuth>
            <ContractStepperPage2 />
          </RequireAuth>
        ),
        children: [
          {
            path: "",
            element: <Navigate to="client" />,
          },
          {
            path: "client",
            id: "contract-clients",
            element: <SellerDetails />,
          },
          {
            path: "agent",
            element: <SellerAgent />,
          },
          {
            path: "solicitor",
            element: <SellerSolicitor />,
          },
          {
            path: "property",
            element: <PropertyStep />,
          },
          {
            path: "special-conditions",
            element: <SpecialConditions />,
          },
          {
            path: "encumbrances",
            element: <Encumbrances />,
          },
          {
            path: "pool-safety",
            element: <PoolSafety />,
          },
          {
            path: "safety-switches-and-alarms",
            element: <SafetyAlarmsAndSwitches />,
          },
          {
            path: "neighbourhood-disputes",
            element: <NeighbourhoodDisputes />,
          },
          {
            path: "annexures",
            element: <Annexures />,
          },
          {
            path: "review",
            element: <Review />,
          },
        ],
      },
      {
        path: "offers/:offerId",
        element: (
          <RequireAuth>
            <OfferStepperPage />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "/workspaces/:workspaceId/agent-appointment",
    element: (
      <RequireAuth>
        <AgentAppointmentPage />
      </RequireAuth>
    ),

    id: "form-six",
  },
  {
    path: "/workspaces/:workspaceId/contract",
    element: (
      <RequireAuth>
        <ContractStepperPage2 />
      </RequireAuth>
    ),
    id: "contract-stepper",
  },

  {
    path: "/workspaces/:workspaceId/document/:documentName",
    element: (
      <RequireAuth>
        <ViewDocument />
      </RequireAuth>
    ),
    id: "view-document",
  },
  {
    path: "/accept-invitation/:invitation",
    element: (
      <RequireAuth>
        <AcceptInvitation />
      </RequireAuth>
    ),
    id: "accept-invitation",
  },
  {
    path: "/settings",
    element: (
      <RequireAuth>
        <SettingsPage />
      </RequireAuth>
    ),
    id: "settings",
    children: [
      {
        path: "my-details",
        element: (
          <RequireAuth>
            <MyDetails />
          </RequireAuth>
        ),
      },
      {
        path: "licencee",
        element: (
          <RequireAuth>
            <Licencee />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "/manage-users",
    element: (
      <RequireAuth>
        <ManageUsers />
      </RequireAuth>
    ),
    id: "manage-users",
  },
]);

const theme = createTheme({
  palette: {
    primary: {
      main: "#014597",
      dark: "#023676",
      light: "#94a6c9",
      contrastText: "#fafafa",
    },
    secondary: {
      main: "#f5793b",
    },

    background: {
      default: "#f9f9f9", // Set your desired color
      paper: "#fcfcfc",
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
});

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Box>
            <RouterProvider router={router} />
          </Box>
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
