import { Box, styled } from "@mui/material";
import { Participant } from "../generated";
import { participantName } from "../helpers/helpers";
import LCCard from "./cards/lc-card";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";

export interface ParticipantCardProps {
  participant: Participant;
}
const ParticipantCard: React.FC<ParticipantCardProps> = ({ participant }) => {
  return (
    <Box width={600}>
      <LCCard
        title={"Seller's Agent"}
        titleColor="#000"
        txtColor="#000"
        bgColor="#CDC5B4"
        circleElementBefore="#8f8c87"
        circleElementAfter="#dfd5bf"
      >
        <Box sx={{ color: "#000" }}>
          <Box
            sx={{ fontSize: "18px", margin: "0 0 15px 0" }}
            className="roboto-regular"
          >
            {participantName(participant)}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              marginBottom: 1,
            }}
          >
            <PhoneAndroidOutlinedIcon />
            <StyledLink
              href={`tel:${participant.phone}`}
              sx={{ color: "#000", textDecoration: "none" }}
            >
              {participant.phone}
            </StyledLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              marginBottom: 1,
            }}
          >
            <AlternateEmailOutlinedIcon />{" "}
            <StyledLink
              href={`mailto:${participant.email}`}
              sx={{ color: "#000", textDecoration: "none" }}
            >
              {participant.email}
            </StyledLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              marginBottom: 1,
            }}
          >
            <PinDropOutlinedIcon />
            <StyledLink sx={{ color: "#000", textDecoration: "none" }}>
              {participant.streetAddress1}
            </StyledLink>
          </Box>
        </Box>
      </LCCard>
    </Box>
  );
};

const StyledLink = styled("a")(({ theme }) => ({
  ...theme.typography.body2,
  color: "#000",
}));

export default ParticipantCard;
