import { Grid, Typography, useTheme } from "@mui/material";
import { FC, useEffect } from "react";
import ParticipantNameForm from "../../../components/participant-name-form";
import ContactDetails from "../../../components/contact-details";
import AddressForm from "../../../components/address-form";
import { Participant1 } from "../../../generated";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { SettingsStatus, settingsState } from "../settings-slice";
import { getMyDetails } from "../thunks";

const MyDetails: FC = () => {
  const theme = useTheme();
  const state = useAppSelector(settingsState);
  const dispatch = useAppDispatch();

  const form = useForm<Participant1>({
    defaultValues: state.myDetails,
    values: state.myDetails,
  });

  const { control, setValue, reset } = form;

  useEffect(() => {
    if (state.myDetailsStatus == SettingsStatus.initial) {
      dispatch(getMyDetails());
    }
    if (state.myDetailsStatus == SettingsStatus.updated) {
      reset(state.myDetails);
    }
  }, [dispatch, state.myDetailsStatus, state.myDetails, reset]);

  // function handleSave(data: Participant1): void {
  //   dispatch(updateMyDetails(data));
  // }

  //useAutoSave(form, handleSave);

  return (
    <form>
      <Grid container spacing={2}>
        <ParticipantNameForm
          control={control}
          captureMiddleName={true}
          captureGst={true}
          participantType={"individual"}
        />

        <Grid item xs={12} sm={12}>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            Contact
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ContactDetails control={control} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            Address
          </Typography>
        </Grid>
        <AddressForm
          control={control}
          address={state.myDetails}
          setValue={setValue}
        />
      </Grid>
    </form>
  );
};

export default MyDetails;
