import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { Box } from "@mui/material";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { saveContract } from "../thunks";

const PoolSafety: React.FC = () => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();

  function onChanged(event: ChangeEvent<HTMLInputElement>): void {
    const newContract = { ...state.contract };

    switch (event.currentTarget.name) {
      case "hasPool":
        newContract.hasPool = event.currentTarget.value == "true";
        if (!newContract.hasPool) {
          newContract.hasPoolCertificate = false;
        }
        break;
      case "hasPoolCertificate":
        newContract.hasPoolCertificate = event.currentTarget.value == "true";
        break;
    }
    dispatch(saveContract(newContract));
  }

  return (
    <FormWrapper title="Part 7 - Pool Safety">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item sm={12} lg={12}>
            <Typography variant="body1">
              Is there a pool on the Land or on adjacent land used in
              association with the Land?
            </Typography>
          </Grid>
          <Grid item sm={12} lg={12}>
            <RadioGroup
              value={state.contract.hasPool ? state.contract.hasPool : false}
              name="hasPool"
              onChange={onChanged}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>

          {state.contract.hasPool ? (
            <React.Fragment>
              <Grid item sm={12} lg={12}>
                <Typography variant="body1">
                  Is there a Pool Compliance Certificate for the pool at the
                  time of contract?
                </Typography>
              </Grid>
              <Grid item sm={12} lg={12}>
                <RadioGroup
                  value={
                    state.contract.hasPoolCertificate
                      ? state.contract.hasPoolCertificate
                      : false
                  }
                  onChange={onChanged}
                  name="hasPoolCertificate"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </React.Fragment>
          ) : null}
        </Grid>
      </Box>
    </FormWrapper>
  );
};

export default PoolSafety;
