import { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CreateWorkspaceStatus,
  changeAddress,
  createWorkspaceState,
} from "./create-workspace-slice";
import { Autocomplete, Box, CircularProgress, Typography } from "@mui/material";
import { useDebounce } from "usehooks-ts";
import { createWorkspace, getTenancyUsers, lookupAddress } from "./thunks";
import { useNavigate } from "react-router-dom";
import { User } from "../../generated";
import LCButton from "../../components/button";

const CreateWorkspacePage: FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(createWorkspaceState);
  const navigate = useNavigate();
  const [inputAddress, setInputAddress] = useState("");
  const debouncedAddress = useDebounce<string>(inputAddress, 1000);
  const [selectedAgent, setSelectedAgent] = useState<User | null>(null);
  const [addressError, setAddressError] = useState<string | null>(null);
  const [agentError, setAgentError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (state.status === CreateWorkspaceStatus.initial) {
      dispatch(getTenancyUsers());
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (inputAddress) {
      dispatch(lookupAddress(inputAddress));
    }
  }, [debouncedAddress, dispatch, inputAddress]);

  function onCreateWorkspace(): void {
    setAddressError(null);
    setAgentError(null);
    setIsLoading(true);

    const address = state.matches.find((m) => m.sla === state.address);

    if (!address) {
      setAddressError("Address is required.");
    }

    if (!selectedAgent) {
      setAgentError("Agent is required.");
    }

    if (address && selectedAgent) {
      setIsLoading(true); // Start loading
      console.log("Starting workspace creation");

      dispatch(
        createWorkspace({ cid: address.pid, agentId: selectedAgent.id })
      )
        .then((value) => {
          console.log("Workspace created:", value);
          navigate(`/workspaces/${value.payload}`);
        })
        .catch((error) => {
          console.error("Error creating workspace:", error);
        })
        .finally(() => {
          console.log("Navigation complete");
          setIsLoading(false); // End loading
        });
    }
  }

  function handleAddressChange(_event: any, address: string | null): void {
    setInputAddress(address || "");
    setAddressError(null);
  }

  function handleAgentChange(_event: any, agent: User | null): void {
    setSelectedAgent(agent);
    setAgentError(null);
  }

  const isOptionEqualToValue = (option: any, value: any) =>
    option.id === value.id;

  return (
    <>
      <Grid item xs={0} sm={3}></Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              className="roboto-medium"
              variant="h5"
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              Start a new workspace
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography className="roboto-regular" sx={{ marginBottom: "5px" }}>
              Search for a property
            </Typography>
            <Autocomplete
              disablePortal
              id="address"
              fullWidth
              size="small"
              filterOptions={(x) => x}
              onInputChange={handleAddressChange}
              onChange={(_event, address) => dispatch(changeAddress(address!))}
              options={state.matches.map<string>((m) => m.sla)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address"
                  error={!!addressError}
                  helperText={addressError}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Typography className="roboto-regular" sx={{ marginBottom: "5px" }}>
              Choose the agent for this property
            </Typography>
            <Autocomplete
              disablePortal
              id="agent"
              fullWidth
              size="small"
              value={selectedAgent}
              isOptionEqualToValue={isOptionEqualToValue}
              onChange={handleAgentChange}
              options={state.users.map<User>((user) => ({
                id: user.sub,
                firstName: user.participant.firstName!,
                lastName: user.participant.lastName!,
              }))}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Agent"
                  error={!!agentError}
                  helperText={agentError}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 3,
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Box
                sx={{
                  width: "200px",
                }}
              >
                <LCButton
                  label="Create Workspace"
                  color="#8F1219"
                  onClick={onCreateWorkspace}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={0} sm={12}></Grid>
    </>
  );
};

export default CreateWorkspacePage;
