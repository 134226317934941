import Grid from "@mui/material/Grid";
import { OfferFormProps } from "./events";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { DateField } from "@mui/x-date-pickers/DateField";
import { Box, Typography, useTheme } from "@mui/material";
import FormWrapper from "../../agent-appointment/components/form-wrapper";

const Settlement: React.FC<OfferFormProps> = ({ offer, onOfferChanged }) => {
  const theme = useTheme();

  function onSettlementDateChanged(value: Dayjs | null): void {
    const newOffer = { ...offer };
    if (value) {
      newOffer.settlementDate = value.toISOString();
    } else {
      newOffer.settlementDate = "0001-01-01T00:00:00Z";
    }
    onOfferChanged(newOffer);
  }

  return (
    <FormWrapper title="Part 3 - Settlement Date">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Settlement Date
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <DateField
              variant="filled"
              size="small"
              label="Settlement Date"
              value={dayjs(offer.settlementDate)}
              onChange={onSettlementDateChanged}
            />
          </Grid>
        </Grid>
      </Box>
    </FormWrapper>
  );
};

export default Settlement;
