import { FC } from "react";
import { Workflow } from "../../../generated";
import { Chip, ChipProps } from "@mui/material";
export interface WorkflowStatusProps extends ChipProps {
  workflow?: Workflow;
}

const WorkflowStatus: FC<WorkflowStatusProps> = ({
  workflow,
  ...restProps
}) => {
  let backgroundColour = "";
  switch (workflow?.state) {
    case "Agent appointment":
      backgroundColour = "yellow";
      break;
    case "Contract disclosures":
      backgroundColour = "blue";
      break;
    case "Accepting offers":
      backgroundColour = "green";
      break;

    default:
      break;
  }
  return (
    <Chip
      label={workflow?.label}
      size="small"
      variant="filled"
      sx={{
        ...restProps.sx,
        border: "solid 2px white",
        color: "white",
        backgroundColor: backgroundColour,
        padding: "4px 4px 4px 4px",
      }}
    />
  );
};

export default WorkflowStatus;
