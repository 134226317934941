import { FC } from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";


export interface SendAgentAppointmentPageProps {
  onDownload: () => void;
  onCancel: () => void;
  open: boolean;

}

const SendAgentAppointmentDialog: FC<SendAgentAppointmentPageProps> = ({open, onDownload, onCancel}) => {
  
  return (
    <Dialog open={open}>
      <DialogTitle>
        Send the agent appointment to the seller for signing
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Download the agent appointment contract and use for signing.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onDownload}>
          Complete Task
        </Button>
      </DialogActions>
      
    </Dialog>
  );
};

export default SendAgentAppointmentDialog;
