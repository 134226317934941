import { FC } from "react";
import { Workflow, WorkspaceSummaryNew } from "../../../generated";
import Typography from "@mui/material/Typography";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@mui/material";
import { fullName, singleLineAddress } from "../../../helpers/helpers";
import { OnWorkspaceAction } from "../../workspace/events";
import WorkflowStatus from "./workflow-status";
export interface WorkspaceSummaryProps {
  workspaceSummary?: WorkspaceSummaryNew;
  workflow?: Workflow;
  onClick?: OnWorkspaceAction;
  isCardView?: boolean;
}
const WorkspaceSummary: FC<WorkspaceSummaryProps> = ({
  workspaceSummary,
  workflow,
  onClick,
  isCardView,
}) => {
  function handleSomeKindOfAction(): void {
    onClick!(workspaceSummary!);
  }

  const sellers = workspaceSummary?.sellers
    .map((seller) => fullName(seller))
    .join(", ");
  return (
    <Card
      style={{
        boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
      }}
    >
      <CardActionArea
        onClick={() => {
          handleSomeKindOfAction();
        }}
      >
        {isCardView && (
          <Box sx={{ position: "relative" }}>
            <CardMedia
              component="img"
              height="250"
              image={`https://landconnex-prod-public.ap-south-1.linodeobjects.com/${workspaceSummary?.id}.jpeg`}
              alt="House"
            ></CardMedia>
            <WorkflowStatus
              workflow={workflow}
              sx={{
                padding: "12px 8px 12px 8px",
                position: "absolute",
                top: 16, // Adjust as needed
                right: 16, // Adjust as needed
              }}
            />
          </Box>
        )}
        <CardContent>
          <Typography gutterBottom variant="h6">
            {singleLineAddress(workspaceSummary?.property!)}
          </Typography>
          <Typography gutterBottom variant="body2" color="text.secondary">
            Sellers: {sellers}
          </Typography>
          {/* <Typography gutterBottom variant="body2" color="text.secondary">
            Status:{" "}
            <WorkflowStatus
              color="success"
              sx={{
                border: "solid 2px white",
                color: "white",
                padding: "4px 4px 4px 4px",
              }}
              workflow={workflow}
            />
          </Typography> */}

          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
          ></Typography>
          {/* <WorkflowStatus workflow={workflow!} date={formattedDate} /> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default WorkspaceSummary;
