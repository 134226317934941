import Grid from "@mui/material/Grid";
import React, { Fragment } from "react";
import { Participant } from "../../../generated";
import { OnParticipantChanged } from "../../../components/events";
import BuyerDetail from "./buyer-detail";
import LCButton from "../../../components/button";
import FormWrapper from "../../agent-appointment/components/form-wrapper";

export type OnAddBuyer = () => void;

export interface BuyersDetailsProps {
  buyers: Array<Participant>;
  onBuyersDetailsChanged: OnParticipantChanged;
  onAddBuyer: OnAddBuyer;
  onBuyerRemoved: OnParticipantChanged;
}
const BuyersDetails: React.FC<BuyersDetailsProps> = ({
  buyers,
  onBuyersDetailsChanged,
  onAddBuyer,
  onBuyerRemoved,
}) => {
  return (
    <FormWrapper title="Part 1 - Buyer's Details">
      <Fragment>
        {buyers.map((buyer, index) => (
          <BuyerDetail
            key={buyer.id}
            buyer={buyer}
            onChanged={onBuyersDetailsChanged}
            onDelete={onBuyerRemoved}
            index={index}
          />
        ))}

        <Grid container item spacing={2} justifyContent={"center"}>
          <Grid item xs={5} sm={3}>
            <LCButton
              label="add another buyer"
              color="#000"
              onClick={() => onAddBuyer()}
            />
          </Grid>
        </Grid>
      </Fragment>
    </FormWrapper>
  );
};

export default BuyersDetails;
