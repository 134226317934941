import { FC, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import LCCard from "../../../components/cards/lc-card";
import LCButton from "../../../components/button";
import KonveiModal from "../../../components/modal/konvei-modal";
import { TeamInvitation } from "../../../generated";

interface PendingInvitationsProps {
  teamInvitations: TeamInvitation[];
  handleAddInvite: (email: string) => void;
  handleDeleteInvite: (invitationId: number) => void;
}

const PendingInvitations: FC<PendingInvitationsProps> = ({
  teamInvitations,
  handleAddInvite,
  handleDeleteInvite,
}) => {
  const [newInvite, setNewInvite] = useState<string>("");
  const [deleteEmail, setDeleteEmail] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const addInvite = () => {
    handleAddInvite(newInvite);
    setNewInvite("");
  };

  const openDeleteModal = (invitationId: number) => {
    setDeleteEmail(invitationId);
    setModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteEmail(0);
    setModalOpen(false);
  };

  const confirmDelete = () => {
    handleDeleteInvite(deleteEmail);
    closeDeleteModal();
  };

  const renderDeleteConfirmation = () => {
    return (
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Confirm Deletion
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Are you sure you want to delete invitation for {deleteEmail}?
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}
        >
          <LCButton label="Cancel" color="#000" onClick={closeDeleteModal} />
          <LCButton label="Delete" color="#8F1219" onClick={confirmDelete} />
        </Box>
      </Box>
    );
  };

  return (
    <LCCard
      title="Pending Invitations"
      titleColor="#000"
      txtColor="#000"
      bgColor="#fff"
      circleElementBefore="#8f8c87"
      circleElementAfter="#dfd5bf"
    >
      <List sx={{ position: "relative", zIndex: 1 }}>
        {teamInvitations.map((email, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => openDeleteModal(email.id)}
              >
                <DeleteForeverOutlinedIcon sx={{ color: "#8F1219" }} />
              </IconButton>
            }
          >
            <ListItemText primary={email.emailAddress} />
          </ListItem>
        ))}
      </List>
      <TextField
        label="Provide email address to send an invitation"
        variant="filled"
        fullWidth
        value={newInvite}
        onChange={(e) => setNewInvite(e.target.value)}
        sx={{ mt: 2 }}
      />
      <Box sx={{ margin: "15px auto 0", maxWidth: 150 }}>
        <LCButton label="Invite" color="#8F1219" onClick={addInvite} />
      </Box>

      <KonveiModal
        open={modalOpen}
        onClose={closeDeleteModal}
        children={renderDeleteConfirmation()}
      />
    </LCCard>
  );
};

export default PendingInvitations;
