import { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import styles from "./footer.module.css";

export const Footer: FC = (): ReactElement => {
  return (
    <Box component="footer" className={`${styles["footer-wrapper"]}`}>
      <Container maxWidth="xl">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Container sx={{ padding: "24px 0" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: { md: 10, xs: 2 },
                  flexDirection: { md: "row", xs: "column" },
                }}
              >
                <Box sx={{ flex: "1 1" }}>
                  <Typography variant="h6" gutterBottom>
                    About
                  </Typography>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    Founded with a passion for connecting people with their
                    ideal homes, we specialize in residential, commercial,
                    luxury properties. Our mission is to provide exceptional
                    service, foster community growth, and ensure client
                    satisfaction.
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignSelf: "center", gap: 10 }}>
                  <Box component="div">
                    <Typography variant="h6" gutterBottom>
                      Utilities
                    </Typography>
                    <ul className="footer-as">
                      <li>
                        <a href="http://.com/category/c-language/">Settings</a>
                      </li>
                      <li>
                        <a href="http://.com/category/front-end-development/">
                          Mail Listings
                        </a>
                      </li>
                      <li>
                        <a href="http://.com/category/java-programming-language/">
                          FAQ
                        </a>
                      </li>
                    </ul>
                  </Box>
                  <Box component="div">
                    <Typography variant="h6" gutterBottom>
                      Quick Links
                    </Typography>
                    <ul className="footer-as">
                      <li>
                        <a href="http://.com/about/">Home</a>
                      </li>
                      <li>
                        <a href="http://.com/contact/">Workspaces</a>
                      </li>

                      <li>
                        <a href="http://.com/sitemap/">Sitemap</a>
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
              <hr />
              <Grid
                container
                justifyContent="space-between"
                sx={{
                  padding: "24px 0",
                  flexDirection: {
                    md: "row",
                    xs: "column-reverse",
                  },
                  alignContent: {
                    md: "left",
                    xs: "center",
                  },
                  gap: {
                    md: 0,
                    xs: 5,
                  },
                }}
              >
                <Grid item xs={12} md={10}>
                  <Typography variant="body2" color="textSecondary">
                    Copyright &copy; {new Date().getFullYear()} All Rights
                    Reserved
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                    <LocalPhoneOutlinedIcon sx={{ color: "#8F1219" }} />
                    <EmailOutlinedIcon sx={{ color: "#8F1219" }} />
                    <MapOutlinedIcon sx={{ color: "#8F1219" }} />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
