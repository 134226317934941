import React from "react";
import { Typography, Box } from "@mui/material";
import { AgentAppointmentStatus } from "../agent-appointment-slice";
import LCCard from "../../../components/cards/lc-card";
import LCButton from "../../../components/button";

interface StatusMessageProps {
  status: AgentAppointmentStatus;
  handleSendAgentAppointment: () => void;
}

const StatusMessage: React.FC<StatusMessageProps> = ({
  status,
  handleSendAgentAppointment,
}) => (
  <LCCard
    title="Send agent appointment for signing"
    txtColor="#000"
    titleColor="#000"
    bgColor="#fff"
  >
    {status == AgentAppointmentStatus.sentForSigning ? (
      <Typography variant="body2">
        <li>The seller will receive the agent appointment for e-signing.</li>
        <li>You will receive the agent appointment for e-signing.</li>
        <li>
          The agent appointment will be added to the workspace documents list.
        </li>
        <li>
          When both parties have signed, the seller will be invited into the
          workspace.
        </li>
      </Typography>
    ) : (
      <Typography variant="body2">
        The document has been sent for signing.
      </Typography>
    )}
    {status != AgentAppointmentStatus.sentForSigning ? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 3,
        }}
      >
        <Box sx={{ width: 150 }}>
          <LCButton
            label="Send"
            color="#8F1219"
            onClick={() => {
              handleSendAgentAppointment();
            }}
          />
        </Box>
      </Box>
    ) : null}
  </LCCard>
);

export default StatusMessage;
