import React from "react";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/system";
import { SvgIconProps } from "@mui/material";

interface LCButtonProps {
  label?: any;
  color?: string;
  txtColor?: string;
  size?: "small" | "medium" | "large";
  borderRadius?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  startIcon?: React.ReactElement<SvgIconProps>;
  endIcon?: React.ReactElement<SvgIconProps>;
}

const LCButton: React.FC<LCButtonProps> = ({
  label,
  color = "#1976d2",
  txtColor = "#fff",
  size = "medium",
  borderRadius,
  onClick = () => {},
  disabled = false,
  startIcon,
  endIcon,
}) => {
  const sizeStyles: { [key: string]: SxProps } = {
    small: {
      height: "30px",
      fontSize: "11px",
      borderRadius: borderRadius || "15px",
    },
    medium: {
      height: "50px",
      fontSize: "16px",
      borderRadius: borderRadius || "30px",
    },
    large: {
      height: "70px",
      fontSize: "21px",
      borderRadius: borderRadius || "40px",
    },
  };

  const sx: SxProps = {
    backgroundColor: disabled ? "#dedede" : color,
    color: txtColor,
    border: `3px solid ${disabled ? "#dedede" : color}`,
    width: "100%",
    ...sizeStyles[size],
    "&:hover": {
      backgroundColor: "none",
      color: color,
    },
  };

  return (
    <Button
      onClick={onClick}
      sx={sx}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {label}
    </Button>
  );
};

export default LCButton;
