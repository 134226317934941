import Grid from "@mui/material/Grid";
import { FC, Fragment, useEffect } from "react";
import PDFViewer from "../../../components/pdf-viewer";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import LCCard from "../../../components/cards/lc-card";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  ContractPreviewStatus,
  contractStepperState,
} from "../contract-stepper-slice";
import { completeContractDrafting, getPdf } from "../thunks";

const Review: FC = () => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);

  function handleComplete(): void {
    dispatch(completeContractDrafting(workspaceId));
  }

  useEffect(() => {
    if (state.contractPreviewStatus == ContractPreviewStatus.initial) {
      dispatch(getPdf(workspaceId));
    }
  }, [state.status]);

  return (
    <Fragment>
      {state.contractPreviewStatus == ContractPreviewStatus.loading ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress size={100}></CircularProgress>
        </Grid>
      ) : null}
      {state.contractPreviewStatus == ContractPreviewStatus.ready ? (
        <Fragment>
          <LCCard
            title="Complete contract preparation?"
            txtColor="#000"
            titleColor="#000"
            bgColor="#fff"
          >
            <Typography>
              When you have completed preparing the contract and contract
              disclosures. Notify the workspace by completing this task. Offers
              to buyers cannot be send until you finish this activity.
            </Typography>
            <Button variant="contained" onClick={handleComplete}>
              Complete
            </Button>
          </LCCard>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item>
              <PDFViewer url={state.url} />
            </Grid>
          </Grid>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default Review;
