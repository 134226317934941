import Button from "@mui/material/Button";
import { FC, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

export type OnCompleteAgentAppointment = (agentAppointment: File) => void;
export interface CompleteAgentAppointmentUploadedDialogProps {
  open: boolean;
  onComplete: OnCompleteAgentAppointment;
  onCancel: () => void;
}

const CompleteAgentAppointmentUploadedDialog: FC<
  CompleteAgentAppointmentUploadedDialogProps
> = ({ open, onComplete, onCancel }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState<File>();
  const [content, setContent] = useState(
    "Upload the signed agent appointment form"
  );

  const handleButtonClick = () => {
    fileInputRef.current?.click(); // Safely access the current property
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFileName(files[0]);
      setContent(`Upload ${files[0].name}`);
    }
  };
  function onCompleteClick(): void {
    if (fileName) {
      onComplete(fileName);
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>
        Upload the signed agent appointment into the workspace
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item>
            <Typography variant="body2">
              Choose the file from your device and then upload.
              <Button variant="outlined" onClick={handleButtonClick}>
                Browse...
              </Button>
            </Typography>
            <input
              type="file"
              style={{ display: "none" }} // Hide the file input
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="application/pdf"
              multiple={false}
            />
          </Grid>
          <Grid item>{content}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onCompleteClick()}
        >
          Complete
        </Button>
      </DialogActions>
    </Dialog>
    // <ActionAlert content={content} severity="information">
    //   <div>
    //     <input
    //       type="file"
    //       style={{ display: "none" }} // Hide the file input
    //       ref={fileInputRef}
    //       onChange={handleFileChange}
    //       accept="application/pdf"
    //       multiple={false}
    //     />
    //     <Button size="small" variant="outlined" onClick={handleButtonClick}>
    //       Upload File...
    //     </Button>
    //     {fileName ? (
    //       <Button
    //         size="small"
    //         variant="outlined"
    //         onClick={() => {
    //           onCompleteClick();
    //         }}
    //       >
    //         Complete
    //       </Button>
    //     ) : (
    //       <></>
    //     )}
    //   </div>
    // </ActionAlert>
  );
};

export default CompleteAgentAppointmentUploadedDialog;
