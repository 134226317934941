import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getAddress, lookupAddress, getTenancyUsers } from "./thunks";
import {
  FlattenedAddress,
  PhysicalAustralianAddress,
  TeamParticipant,
} from "../../generated";

export enum CreateWorkspaceStatus {
  initial,
  ready,
}
interface MattersState {
  status: CreateWorkspaceStatus;
  address: string;
  property?: PhysicalAustralianAddress;
  matches: Array<FlattenedAddress>;
  users: Array<TeamParticipant>;
}

const initialState: MattersState = {
  status: CreateWorkspaceStatus.initial,
  address: "",
  matches: [],
  users: [],
};
export const createWorkspaceSlice = createSlice({
  name: "createWorkspace",
  initialState,
  reducers: {
    changeAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
  },
  extraReducers: (builder) => {
    lookupAddressReducer(builder);
    getAddressReducer(builder);
    getUsersReducer(builder);
  },
});

function getUsersReducer(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(getTenancyUsers.fulfilled, (state, action) => {
    
    
    state.users = action.payload;
    state.status = CreateWorkspaceStatus.ready;
  });
}

function lookupAddressReducer(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(lookupAddress.fulfilled, (state, action) => {
    state.matches = action.payload;
    state.status = CreateWorkspaceStatus.ready;
  });
}
function getAddressReducer(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(getAddress.fulfilled, (state, action) => {
    state.property = action.payload;
    state.status = CreateWorkspaceStatus.ready;
  });
}

export const { changeAddress } = createWorkspaceSlice.actions;
export default createWorkspaceSlice.reducer;
export const createWorkspaceState = (state: RootState) => state.createWorkspace;
