import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  debounce,
} from "@mui/material";
import { Contract, Participant } from "../../../generated";
import {
  OnContractChanged,
  OnParticipantChanged,
} from "../../../components/events";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useDeepCompareEffect from "use-deep-compare-effect";
import ParticipantNameForm from "../../../components/participant-name-form";
import ContactDetails from "../../../components/contact-details";
import AddressForm from "../../../components/address-form";
import { participantSchema } from "../../agent-appointment/components/schemas";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import FormInputText from "../../../components/form-input-text";
import LCButton from "../../../components/button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "../../../components/button";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { useParams } from "react-router-dom";
import {
  getCurrentUserDetails,
  saveContractParticipant,
} from "../thunks";

export interface SellerSolicitorProps {
  participant: Participant;
  onParticipantChanged: OnParticipantChanged;
  onContractChanged: OnContractChanged;
  workspaceRole?: string;
  contract: Contract;
  // onCopySolicitorDetails: OnWorkspaceEvent;
}

const SellerSolicitor: React.FC = () => {
  const schema = participantSchema;
  const theme = useTheme();

  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);

  const handleOnParticipantChanged = (data: Participant) => {
    dispatch(
      saveContractParticipant({ workspaceId: workspaceId, participant: data })
    );
  };

  const { handleSubmit, control, formState, reset, trigger, setValue } =
    useForm<Participant>({
      defaultValues: state.sellerSolicitor,
      resolver: yupResolver(schema),
    });

  // const contractSchema = yup
  //   .object<Contract>({
  //     depositHolderName: yup.string().required(),
  //     depositHolderAccountName: yup.string().required(),
  //     depositHolderBsb: yup.string().required(),
  //     depositHolderAccountNumber: yup.string().required(),
  //     depositHolderBank: yup.string().required(),
  //   })
  //   .required();

  // const {
  //   handleSubmit: contractHandleSubmit,
  //   control: contractControl,
  //   formState: contractFormState,
  //   reset: contractReset,
  //   trigger: contractTrigger,
  // } = useForm<any>({
  //   defaultValues: state.contract || {},
  //   resolver: yupResolver(contractSchema),
  // });

  // useEffect(() => {
  //   contractReset(state.contract || {});
  //   contractTrigger();
  // }, [state.contract]);


  const [showDepositHolderForm, setShowDepositHolderForm] = useState(false);

  useEffect(() => {
    reset(state.sellerSolicitor);
    trigger();
  }, [state.sellerSolicitor]);

  const onChangedSubmit: SubmitHandler<Participant> = (data) => {
    handleOnParticipantChanged(data);
  };

  console.log(state.contract);

  const watchedData = useWatch({
    control: control,
    defaultValue: state.sellerSolicitor,
  });

  const debouncedSave = useCallback(
    debounce(() => {
      handleSubmit(onChangedSubmit)();
    }, 1000),
    []
  );

  useDeepCompareEffect(() => {
    if (formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);

  // const onContractChangedSubmit: SubmitHandler<Contract> = (data) => {
  //   dispatch(saveContract(data));
  // };

  // const debouncedContractSave = useCallback(
  //   debounce(() => {
  //     contractHandleSubmit(onContractChangedSubmit)();
  //   }, 1000),
  //   []
  // );

  // const watchedContractData = useWatch({
  //   control: contractControl,
  //   defaultValue: state.contract || {},
  // });

  // useDeepCompareEffect(() => {
  //   debouncedContractSave();
  // }, [watchedContractData]);

  // const handleInputChange = useCallback(
  //   (field: keyof Contract) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const updatedContract = {
  //       ...watchedContractData,
  //       [field]: event.target.value,
  //     };
  //     onChangedSubmit(updatedContract);
  //   },
  //   [watchedContractData, onChangedSubmit]
  // );

  const handleCopySolicitorDetails = () => {
    dispatch(getCurrentUserDetails());
  };

  return (
    <FormWrapper title="Part 3 - Seller's Solicitor">
      <Box
        sx={{
          maxWidth: "1024px",
          margin: "15px auto",
          padding: "30px",
          background: "#faf7f7",
          borderRadius: "30px",
          boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Button
              size="small"
              onClick={() => {
                handleCopySolicitorDetails();
              }}
              label="My details"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Name {state.sellerSolicitor.id}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInputText
              name="firstName"
              required
              label="First name"
              control={control}
              key="firstName"
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormInputText
              name="lastName"
              required
              label="Last name"
              control={control}
              key="lastName"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <ParticipantNameForm
              control={control}
              participantType={state.sellerSolicitor.participantType!}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Contact
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <ContactDetails control={control} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color={theme.palette.text.secondary}>
              Address
            </Typography>
          </Grid>

          <AddressForm
            address={state.sellerSolicitor}
            setValue={setValue}
            control={control}
          />
        </Grid>

        {state.workspace.workspace?.role === "sellerSolicitor" && (
          <>
            <Box sx={{ marginTop: 2, width: 200, zIndex: 1 }}>
              <LCButton
                label="Deposit Holder"
                color="#000"
                onClick={() => setShowDepositHolderForm(!showDepositHolderForm)}
                startIcon={
                  showDepositHolderForm ? <RemoveIcon /> : <AddOutlinedIcon />
                }
              />
            </Box>

            {/* {showDepositHolderForm && (
              <Box sx={{ marginTop: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color={theme.palette.text.secondary}
                    >
                      Deposit Holder Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderName")}
                      defaultValue={state.contract.depositHolderName || ""}
                      name="depositHolderName"
                      label="Deposit Holder Name"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderAccountName")}
                      defaultValue={
                        state.contract.depositHolderAccountName || ""
                      }
                      name="depositHolderAccountName"
                      label="Deposit Holder Account Name"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderBsb")}
                      defaultValue={state.contract.depositHolderBsb || ""}
                      name="depositHolderBsb"
                      label="Deposit Holder BSB"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderAccountNumber")}
                      defaultValue={
                        state.contract.depositHolderAccountNumber || ""
                      }
                      name="depositHolderAccountNumber"
                      label="Deposit Holder Account Number"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={handleInputChange("depositHolderBank")}
                      defaultValue={state.contract.depositHolderBank || ""}
                      name="depositHolderBank"
                      label="Deposit Holder Bank Name"
                      variant="filled"
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Box>
            )} */}
          </>
        )}
      </Box>
    </FormWrapper>
  );
};

export default SellerSolicitor;
